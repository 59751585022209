import styled, { css, keyframes } from "styled-components"

export const Contanier = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`
// Definição das animações
const movetoB = keyframes`
  from { 
    transform: translateY(200%);
  }
  to { 
    transform: translateY(0%);
  }
`;
const movetoT = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(500%);
  }
`;
// Componente estilizado
export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
  padding: 5px;
  gap: 10px;
  z-index: 20;
  overflow: hidden;
  /* Animação condicional */
  animation: ${({ $show }) =>
    $show === false
      ? css`
          ${movetoT} 0.5s forwards;
        `
      : css`
          ${movetoB} 0.5s forwards;
        `};
  animation: ${({ $closing }) =>
    $closing
      ? css`
          ${movetoT} 0.5s forwards;
        `
      : css`
          ${movetoB} 0.5s forwards;
        `};
  @media (max-width: 700px) {
    width: 20rem;
  }
`;
export const Title = styled.h1`
  width: 100%;
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  text-transform: capitalize;
  font-weight: 800;
  color: var(--azul);
  @media (max-width: 700px) {
    margin-left: 2%;
    font-size: 1.6rem;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const DivMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: fit-content;
  gap: 1em;

  h4{
    display: flex;
    align-items: start;
    justify-content: center;
    width: 70%;
    font-weight: 600;
    span{
      padding: 10px;
      margin-right: 10px;
      background-color: var(--button-text-color);
      border-radius: 100px;
    }
  }
  .DivImg{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    gap: 1em;
      img{
        width: ${({$w}) => ($w ? $w : `70%`)};
      }
    }
    @media (max-width: 1000px) {
      flex-direction: column;

      h4{
        width: 90%;
        font-size: small;
        span{
          padding: 7px;
          margin-right: 5px;
        }
      }

      .DivImg{
        font-size: 10px;
        gap: 0.5em;
      }
    }
`

export const Buttons = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: 0.2s ease-in-out;
  margin-left: 10px;
  &:hover {
    transform: translateY(-7px);
  }
`
export const DivButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ $steps }) => {
    if ($steps === 0) return 'end';
    else if ($steps >= 1) return 'space-between';
  }};

`