import React, { useEffect, useState } from "react"
import Input from "../../Input"
import Button from "../../Button"
import { ButtonNew, Buttons, Card, Contanier, Forms, Gruop, Header, Title, paragrafo } from "./style"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import { format } from "date-fns"

export default function ResetarSenha({ resetEvent, setResetEvent }) {
  if (resetEvent == false) { return null }
  const [rm, setRm] = useState("")
  const [nome, setNome] = useState("")
  const [DataNascimento, setDataNascimento] = useState("")
  const [DataInicio, setDataInicio] = useState("")
  const [DataTermino, setDataTermino] = useState("")
  const [Email, setEmail] = useState("")
  const [Curso, setCurso] = useState("")

  useEffect(() => {
    if (resetEvent == false) { return }

    const delayDebounceFn = setTimeout(() => {
      if (rm.length >= 5) {
        submitEvent()
      } else {
        clearUserData()
      }
    }, 500) // Espera 500ms após o usuário parar de digitar

    return () => clearTimeout(delayDebounceFn)
  }, [rm, resetEvent])

  function clearUserData() {
    setNome("")
    setDataNascimento("")
    setDataInicio("")
    setDataTermino("")
    setEmail("")
    setCurso("")
  }

  async function submitEvent() {
    try {
      const res = await api.get(`/aluno/listar?procurar=${rm}`)
      if (res.data && res.data.length > 0) {
        const aluno = res.data[0]
        setNome(aluno.nome)
        setDataNascimento(aluno.dt_nascimento)
        setDataInicio(aluno.dt_inicio)
        setDataTermino(aluno.dt_termino)
        setEmail(aluno.email)
        setCurso(aluno.nome_curso)
      } else {
        Alert("Aviso", "Aluno não encontrado")
        clearUserData()
      }
    } catch (erro) {
      console.log(erro)
      Alert("Erro", "Não foi possível buscar o aluno")
      clearUserData()
    }
  }

  async function resetUser(rm) {
    try {
      const response = await api.put(`/aluno/restaurar-senha?rm=${rm}`)
      if (response.status == 200) {
        Alert("Sucesso", "Senha resetada com sucesso!")
        setResetEvent(false)
      } else {
        Alert("Erro", "Erro ao resetar senha do aluno")
      }
    } catch (err) {
      Alert("Erro", "Erro ao resetar senha do aluno")
      console.log(err)
    }
  }

  return (
    <Contanier>
      <Card>
        <Header>
          <Buttons
            onClick={() => {
              setRm('')
              clearUserData()
              setResetEvent(false)
            }}
          >
            <Icone name={"X"} color={" #3a3f86"} size={50} />
          </Buttons>
          <Title>Resetar Senha</Title>
        </Header>
        <Forms>
          <ButtonNew>
            <Input
              widthDiv="8rem"
              color="black"
              type={"text"}
              placeholdercolor="black"
              placeholder="Digite o RM"
              required={true}
              event={(e) => setRm(e.target.value)}
            />
          </ButtonNew>
          <Gruop>
            {nome && <paragrafo><strong>Nome: </strong>{nome}</paragrafo>}
            {DataNascimento && <paragrafo><strong>Nascimento: </strong>{format(new Date(DataNascimento), "dd/MM/yyyy")}</paragrafo>}
            {Email && <paragrafo><strong>Email: </strong>{Email}</paragrafo>}
            {Curso && <paragrafo><strong>Curso: </strong>{Curso}</paragrafo>}
          </Gruop>
        </Forms>
        <Button
          id={"button"}
          width="20vh"
          height="4rem"
          event={() => resetUser(rm)}
          placeholder={"Resetar"}
        />
      </Card>
    </Contanier>
  )
}