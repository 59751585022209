import Globalstyles from "./globalstyles"
import AppRoutes from "./routes"

function App() {

  return (
    <>
      <AppRoutes />
      <Globalstyles />
    </>
  )
}

export default App
