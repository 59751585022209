import React, { useEffect, useState } from 'react';
import {
  Background, DivSave, Title, Cardo, ContainerTable, Table, Theader, Th, Tbody, Trbody, Td, DivMain, FormDiv,
  DivTitle, DivideTable, Infos, CursoTurmaDiscInfo, Theader2, DivText, DivText2, Grup
} from './style';
import './styleCard.css';
import api from '../../../Services/api';
import { format, parseISO } from 'date-fns';
import Swal from 'sweetalert2';
import Button from '../../Button';
import GerarPDF from '../../../utils/GerarPdfs';

const CardListarAlunosPP = ({ MostrarAlunos, setMostrarAlunos, progressaoEdit }) => {
  const [alunosUnificados, setAlunosUnificados] = useState([]);
  const [alunosConceitos, setAlunosConceitos] = useState([]);

  useEffect(() => {
    if (MostrarAlunos) {
      ListarAlunosProgressoes(progressaoEdit.id);
    }
  }, [MostrarAlunos, progressaoEdit.id]);

  async function ListarAlunosProgressoes(id_progressao) {
    try {
      const response = await api.get(`/professor/listar-alunos-progressoes?id_progressao=${id_progressao}`);
      const { alunosEmProgressao, AlunosStatus } = response.data;

      // Flatten the arrays in case they are nested
      const ListaAlunosEmProgressao = alunosEmProgressao.flat();
      const ListaAlunosStatus = AlunosStatus.flat();

      // Unificar os dados de alunos e seus status
      const dadosUnificados = ListaAlunosEmProgressao.map(aluno => {
        const status = ListaAlunosStatus.find(status => status.id_aluno === aluno.id) || {};
        return {
          ...aluno,
          cumpriu: status.cumpriu,
          conceito: status.conceito
        };
      });

      setAlunosUnificados(dadosUnificados);

      // Atualizar o estado dos conceitos
      const conceitos = dadosUnificados.map(aluno => aluno.conceito).filter(conceito => conceito !== 'Não Avaliado');
      setAlunosConceitos(conceitos.length > 0 ? conceitos[0] : 'Não Avaliado');
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: "Erro",
        text: err.response?.data?.erro || "Erro interno de servidor",
        icon: "error",
        iconColor: "#ff0000",
        confirmButtonColor: "var(--azul)",
        confirmButtonText: "Confirmar",
      }).then((result) => { if (result.isConfirmed) setMostrarAlunos(false) });
    }
  }

  if (!MostrarAlunos) return null;

  // const dataAtual = new Date();
  // const dataFim = new Date(progressaoEdit.data_fim);
  // const terminou = dataAtual > dataFim;
  const DataInicio = progressaoEdit.data_inicio ? format(parseISO(progressaoEdit.data_inicio), "dd/MM/yyyy") : 'N/A';
  const DataFim = progressaoEdit.data_fim ? format(parseISO(progressaoEdit.data_fim), "dd/MM/yyyy") : 'N/A';
  const curso = progressaoEdit.curso_rh?.split(', ')[1];
  const turma = progressaoEdit.turma_rh?.split(", ")[1];
  const disciplina = progressaoEdit.disciplina_rh?.split(', ')[1];
  // const professor = progressaoEdit.professor?.split(', ')[1];

  // const conteudoPDF = {
  //   titulo: 'Resultados da Progressão Parcial',
  //   curso,
  //   turma,
  //   disciplina,
  //   DataInicio,
  //   DataFim,
  //   professor
  // };

  return (
    <Background>
      <Cardo>
        <DivTitle><Title>Alunos Em Progressão Parcial</Title></DivTitle>
        <FormDiv>
          <Infos>
            <CursoTurmaDiscInfo>Curso <p>{curso || 'N/A'}</p></CursoTurmaDiscInfo>
            <CursoTurmaDiscInfo>Turma <p>{turma || 'N/A'}</p></CursoTurmaDiscInfo>
          </Infos>
          <Infos>
            <CursoTurmaDiscInfo>Disciplina <p>{disciplina || 'N/A'}</p></CursoTurmaDiscInfo>
            <CursoTurmaDiscInfo>Início <p>{DataInicio}</p></CursoTurmaDiscInfo>
            <CursoTurmaDiscInfo>Fim <p>{DataFim}</p></CursoTurmaDiscInfo>
          </Infos>
        </FormDiv>
        <ContainerTable>
          {alunosUnificados.length > 0 ? (
            <Table>
              <Theader>
                <tr>
                  <Th>RM</Th>
                  <Th>Nome</Th>
                  <Th>Curso</Th>
                  <Th>Convênio</Th>
                  {/* {terminou && alunosConceitos !== null && (
                    <>
                      <Th>Cumpriu</Th>
                      <Th>Conceito</Th>
                    </>
                  )} */}
                </tr>
              </Theader>
              <Tbody>
                {alunosUnificados.map((aluno) => {
                  // const cumpriu = aluno.cumpriu === 1 ? 'Sim' : aluno.cumpriu === 0 ? 'Não' : 'Não Avaliado';
                  // const conceito = aluno.conceito || 'Não Avaliado';

                  return (
                    <Trbody key={aluno.rm}>
                      <Td className='rm'>{aluno.rm}</Td>
                      <Td>{aluno.nome_aluno}</Td>
                      <Td>{aluno.nome_curso}</Td>
                      <Td>{aluno.convenio}</Td>
                      {/* {terminou && conceito !== 'Não Avaliado' && (
                        <>
                          <Td>{cumpriu}</Td>
                          <Td>{conceito}</Td>
                        </>
                      )} */}
                    </Trbody>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <>
              <Table>
                <Theader>
                  <tr>
                    <Th>Rm</Th>
                    <Th>Nome</Th>
                    <Th>Curso</Th>
                    <Th>Convenio</Th>
                    {/* <Th>Cumpriu</Th> */}
                    {/* <Th>Conceito</Th> */}
                  </tr>
                </Theader>
              </Table>
              <DivText>Nenhum aluno em Progressão</DivText>
            </>
          )}
        </ContainerTable>
        <DivSave>
          <Button
            event={() => { setAlunosUnificados([]); setMostrarAlunos(false); }}
            width="9rem"
            placeholder="Fechar"
            backgroundcolor="#1a2e35"
            borderRadius="10px"
          />
          {/* {terminou && alunosConceitos !== null && (
            <Button
              event={() => GerarPDF(alunosUnificados, conteudoPDF)}
              width="10rem"
              placeholder="Gerar PDF"
              backgroundcolor="#1a2e35"
              borderRadius="10px"
            />
          )} */}
        </DivSave>
      </Cardo>
    </Background>
  );
}

export default CardListarAlunosPP;
