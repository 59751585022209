import React, { useEffect, useState } from 'react'
import "./style.css"
import { ContainerTable2, Contanier, DadosText, DadosHeader, Card2, ImgAluno, Img, ImgAlunoInativo } from './style'
import api from '../../Services/api'
import { useNavigate, useParams } from 'react-router-dom'
import userDefault from "../../assets/images/user.png"
import axios from "axios"
import AlunoInativo from "../../assets/images/AlunoInativo.jpg"
import Url_Image from "../../assets/URL'S/Url_Image"

function DadosPublicos() {
    const nav = useNavigate()
    const params = useParams()
    const [alunoData, setAlunoData] = useState([])
    const [user, setUser] = useState("")
    let imageUrl = ""
    const locale = "pt-BR"


    useEffect(() => {
        const id = params.id
        async function getallalunos() {
            api.get(`/aluno/listar-publico/${id}`)
                .then(async (response) => {
                    setAlunoData(response.data)
                    imageUrl = Url_Image(response.data.rm)
                    setUser(imageUrl ? imageUrl : userDefault)
                    await axios
                        .get(imageUrl)
                        .then((response) => { })
                        .catch((err) => {
                            setUser("undefined")
                            console.log(err)
                        })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        getallalunos()
    }, []);


    return (
        <Contanier>
            {alunoData.rm ? (
                <Card2>
                    {alunoData.ativo === 1 ? (
                        <>
                            <ImgAluno>
                                <Img src={user == "undefined" || !user ? userDefault : user} alt="foto" />
                            </ImgAluno>
                            <ContainerTable2>
                                <DadosHeader>NOME<DadosText>{alunoData.nome_aluno}</DadosText></DadosHeader>
                                <DadosHeader>VALIDADE<DadosText>{new Date(alunoData.dt_termino).toLocaleDateString(locale)}</DadosText></DadosHeader>
                                <DadosHeader>CURSO <DadosText>{alunoData.nome_curso}</DadosText></DadosHeader>
                            </ContainerTable2>
                        </>
                    ) : (
                        <ImgAlunoInativo>
                            <Img src={AlunoInativo} alt="Aluno Inativo" />
                        </ImgAlunoInativo>
                    )}
                </Card2>
            ) : (
                <div />
            )}
        </Contanier >
    )
}

export default DadosPublicos
