import styled from "styled-components"
import { AiOutlineImport, AiOutlineSolution, AiOutlineLogout, AiOutlineArrowLeft } from "react-icons/ai"
import { MdAddPhotoAlternate } from "react-icons/md"

const HintText = "Voltar"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hiden;
  overflow-y: hiden;
`

export const Header = styled.div`
  display: flex;
  display: flex;
  justify-content: start;
  margin: 0 auto;
  width: 90%;
  font-size: 45px;
  align-text: center;
  padding: 10px;
`

export const ReturnIcon = styled(AiOutlineLogout)`
  color: #0d467e;
  transition: transform 0.2s;
  margin-right: -2%;
  cursor: pointer;
  text: "Voltar";

  &:hover {
    transform: scale(1.2) rotate(180deg);
  }
`

export const HeaderText = styled.p`
  color: #0d467e;
  margin: 0 auto;
  width: fit-content;
  padding-bottom: 10px;
  border-bottom: 4px solid #0d467e;
  box-shadow: 0 5px 2px -2px gray;
`

export const Cards = styled.div`
  margin-top: 1.5rem;
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 4rem 0;
  background: rgb(49, 82, 133);
  background: linear-gradient(150deg, rgba(49, 82, 133, 1) 0%, rgba(39, 67, 115, 1) 100%);
`

export const Cardsdiv = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  gap: 4rem;
  background-color: transparent;
`
export const IconeExit = styled(AiOutlineArrowLeft)`
  color: #0d467e;
  margin-top: 1%;
  cursor: pointer;
`

export const CardOne = styled.div`
  width: 400px;
  position: relative;
  height: 500px;
  border-radius: 35px;
  transition: all 0.4s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  cursor: pointer;
  align-self: center;

  &:hover {
    background: rgba(21, 116, 208);
    background: linear-gradient(
      150deg,
      rgba(21, 116, 208, 0.5) 0%,
      rgba(69, 42, 164, 0.2) 72%,
      rgba(217, 15, 34, 0.2) 100%
    );
    transform: scale(1.02);
  }
`

export const CardTwo = styled.div`
  width: 400px;
  height: 500px;
  border-radius: 35px;
  transition: all 0.4s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  cursor: pointer;
  align-self: center;

  &:hover {
    background: rgb(21, 116, 208);
    background: linear-gradient(
      150deg,
      rgba(217, 15, 34, 0.2) 0%,
      rgba(69, 42, 164, 0.2) 72%,
      rgba(21, 116, 208, 0.5) 100%
    );
    transform: scale(1.02);
  }
`

export const CardTree = styled.div`
  width: 400px;
  height: 500px;
  border-radius: 35px;
  transition: all 0.4s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  cursor: pointer;
  align-self: center;

  &:hover {
    background: rgb(21, 116, 208);
    background: linear-gradient(
      150deg,
      rgba(217, 15, 34, 0.2) 0%,
      rgba(69, 42, 164, 0.2) 72%,
      rgba(21, 116, 208, 0.5) 100%
    );
    transform: scale(1.02);
  }
`

export const Check = styled.div`
  display: flex;
  width: 100%;
  justify-items: end;
`

export const IconArea = styled.div`
  align-items: center;
  margin: 0 auto;
`

export const IconTwo = styled(AiOutlineImport)`
  position: relative;
  width: 100%;
  height: 150px;
  color: #0d467e;
  opacity: 90%;

  ${CardTwo}:hover & {
    color: #f0f0f0;
  }
`

export const IconOne = styled(AiOutlineSolution)`
  position: relative;
  width: 100%;
  height: 150px;
  color: #0d467e;
  opacity: 90%;

  ${CardOne}:hover & {
    color: #f0f0f0;
  }
`

export const IconTree = styled(MdAddPhotoAlternate)`
  position: relative;
  width: 100%;
  height: 150px;
  color: #0d467e;
  opacity: 90%;

  ${CardTree}:hover & {
    color: #f0f0f0;
  }
`

export const MiniTitle = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`
export const Mini = styled.p`
  font-size: 35px;
  margin: 0 auto;
  color: #0d467e;

  ${CardOne}:hover & {
    color: #f0f0f0;
  }

  ${CardTwo}:hover & {
    color: #f0f0f0;
  }
  ${CardTree}:hover & {
    color: #f0f0f0;
  }
`

export const TextArea = styled.div`
  display: flex;
  position: relative;
  width: 70%;
  margin: 10% auto 0 auto;
`

export const Text = styled.p`
  font-size: 25px;
  color: #0d467e;
  justify-items: center;
  align-items: center;
  text-align: center;

  ${CardOne}:hover & {
    color: #f0f0f0;
  }

  ${CardTwo}:hover & {
    color: #f0f0f0;
  }
  ${CardTree}:hover & {
    color: #f0f0f0;
  }
`
