import { SlActionUndo } from "react-icons/sl";
import { FaFileImport } from "react-icons/fa"
import { RiArrowGoBackFill } from "react-icons/ri";
import { HiTrash } from "react-icons/hi"
import { RxReset } from "react-icons/rx";
import styled, { keyframes } from "styled-components"

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.Show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 998;

  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--blur-bg-color);
    position: fixed;
    top: 0;
    left: 0;
  }
`

export const Close = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  padding: 20px 0px 0px 50px;
  transition: transform 0.2s;
  // border: 1px solid black;
`

export const Header = styled.div`
  padding: 10px;
  text-align: center;
  color: var(--azul);
  font-size: 25px;
`

export const Container = styled.div`
  z-index: 999;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 2vh;
  height: fit-content;
  width: 40vh;
  background-color: var(--background);
  gap: 0.5%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`

export const Form = styled.form`
  display: flex;
  position: relative;
  gap: 20px;
  /* margin-left: 10%; */
  flex-direction: column;
  width: 75%;
  // border: 1px solid black;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
`
export const PreviewImage = styled.img`
  cursor: pointer;
  width: 5.5rem;
  height: 7.5rem;
  border: 1px solid black;

  @media (max-height: 900px) {
    width: 5rem;
    height: 5rem;
}
`

export const DropDiv2 = styled.div`
  display: flex;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  transition: transform 1s;
  transition: color 1s;

  &:hover {
    cursor: pointer;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 20px;
  width: 100%;
`

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

  &[alt]:after {
    content: "Não suportado!";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: var(--secondary-bg-color);
    color: var(--primary-font-color);
  }
`

export const InputFileManual = styled.input`
  position: absolute;
  opacity: 0%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 80%;
  border-radius: 20px;
  border: 1px solid black;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }
`

export const RetunrIcon = styled(SlActionUndo)`
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  bottom: -2rem;
  left: 1rem;
  z-index: 1;
  color: var(--azul);

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`
export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  bottom: -1rem;
  right: 0;
  z-index: 1;
  color: var(--azul);

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`
export const ButtonUserDefalt = styled.button`
  cursor: pointer;
  position: absolute;
  bottom: -2rem;
  left: 1rem;
  z-index: 1;
  color: white;
  background: #3a3f86;  
  padding: 5px 10px;
  border: unset;
  border-radius: 15px;

  &:hover {
    content: "";
  /* border-radius: 15px; */
  background-color: var(--amarelo);
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms
  }

  &::before &:active {
  background-color: var(--azul);
}
`
