import styled from "styled-components";

export const Contanier = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  /* align-items: center; */
  justify-content: center;
`

export const Card2 = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  background-color: white;
  border-top: 1px solid #1a2e35;
  /* -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3); */
  /* box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.3); */
`
export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 1.5rem;
  }
`
export const Header = styled.div`
  display: flex;
  margin-top: 1%;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 5%;

  @media(max-width: 1350px) {
    margin-top: 1.5%;
  }
`

export const ContainerTable2 = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3,85px);
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2%;
  padding: 1%;
  margin-bottom: 5%;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.3);
  border-radius: 15px;

  @media(max-width: 1150px) {
    width: 60%;
    margin-top: 2%;
    margin-bottom: 10%;
    padding: 2%;
    border-radius: 10px;
    grid-template-rows: repeat(3,60px);
  }
  @media(max-width: 780px) {
    width: 85%;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 2%;
    border-radius: 10px;
    grid-template-rows: repeat(3,50px);
  }

  @media(max-height: 550px) {
    width: 70%;
    margin-bottom: 10%;
    border-radius: 15px;
    grid-template-rows: repeat(3, 65px);
  } 
`
export const DadosHeader = styled.p`
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0);
  border-radius: 10px;
  padding: 3%;

  @media(max-width: 1150px) {
    text-align: center;
    padding: 2%;
  }
  @media(max-width: 780px) {
    font-size: 0.9rem;
    text-align: center;
    padding: 2%;
  }
`

export const DadosText = styled.p`
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;

  @media(max-width: 1150px) {
    font-weight: normal;
    text-align: center;
  }
  
  @media(max-width: 780px) {
    font-size: 0.8rem;
    font-weight: normal;
    text-align: center;
  }
`

export const ImgAluno = styled.div`
  display: flex;
  justify-content: center;
  border: 3px solid black;
  border-radius: 10px;
  margin-top: 2%;
  width: 10%;
  max-height: 250px;
  
  @media(max-width: 780px) {
    width: 30%;

  }
  `
export const ImgAlunoInativo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2%;
  width: 60%;
  
  @media(max-width: 780px) {
    width: 100%;

  }
  `
  export const Img = styled.img`
  display: flex;
  width: 100%;
  border-radius: 10px;
`