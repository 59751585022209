import React from "react";
import naoAutorizado from "../../assets/images/naoautorizado.png"
import { Container, Img } from "./style";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";

export default function NaoAutorizado() {
    const nav = useNavigate();

    if (!!localStorage.getItem("token")) {
        localStorage.removeItem("token")
    }
    if (!!localStorage.getItem("sendAnexoApi")) {
        localStorage.removeItem("sendAnexoApi   ")
    }
    if (!!localStorage.getItem("token2")) {
        localStorage.removeItem("token2")
    }
    if (!!localStorage.getItem("usuario")) {
        localStorage.removeItem("usuario")
    }
    if (!!localStorage.getItem("Entrada")) {
        localStorage.removeItem("Entrada")
    }
    if (!!localStorage.getItem("rm")) {
        localStorage.removeItem("rm")
    }
    if (!!localStorage.getItem("_grecaptcha")) {
        localStorage.removeItem("_grecaptcha")
    }

    return (
        <Container>
            <Img src={naoAutorizado} alt="Não autorizado" />
            {/* <Button event={() => nav('/')} placeholder={'SAIR'} /> */}
        </Container>
    );
};