import { FaFileImport } from "react-icons/fa";
import { HiTrash } from "react-icons/hi";
import styled from "styled-components";


export const Background = styled.div`
width: 100vw;
height: 100vh;
display: flex;
position: absolute;
align-items: center;
 justify-content: center;
background-color: rgba(0,0,0,0.65);
z-index: 10;
top: 0;
/* border: 15px solid black; */
`

export const Cardo = styled.div`
width: 60vw;
height: 85vh;
display: flex;
align-items: center;
flex-direction: column;
background-color: white;
border: 1px solid black;
border-radius: 20px;
padding: 20px 30px;
`
export const DivSave = styled.div`
width: 100%;
height: 12%;
display: flex;
justify-content: space-between;
align-items: end;
padding-top: 10px;
`

export const DivMain = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 5px solid #1a2e35;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
export const DivTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  font-weight: 400;
  border-radius: 20px 20px 0px 0px;
  border-right: 5px solid #1a2e35;
  border-left: 5px solid #1a2e35;
`

export const Title = styled.h1`
  padding: 15px 50px 15px 30px;
`

export const DivText = styled.h2`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items:center;
`

export const DivText2 = styled.h2`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items:center;
`
export const ContainerTable = styled.div`
  width: 100%;
  min-height: 70%;
  overflow-x: hidden;
  overflow-y: auto;
  border: 5px solid #1a2e35;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  &::-webkit-scrollbar {
    width: 0px;
  } 

`
export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
`

export const Theader = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: start;
  position: sticky;
  top: 0;
  height: 5vh;
  font-size: 1rem;
  z-index: 9;
`
export const Theader2 = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: -1px;
  height: 8vh;
  font-size: 1rem;
  z-index: 9;
`

export const Th = styled.th`
  padding: 2px 40px;
  text-align: start;
`

export const ThRm = styled.th`
  padding: 2px 20px;
  text-align: center;
`;

export const ThNome = styled.th`
  padding: 2px 15px;
  text-align: center;
`;

export const Td = styled.td`
  padding: 10px 30px;
  min-height: 20vh;
  
`

export const Tbody = styled.tbody`
  height: fit-content;
  overflow: scroll;
`

export const Trbody = styled.tr`
  border-bottom: 1px solid #dddddd;
  height: 50px;
  text-align: start;
  &:nth-of-type(even) {
  background-color: #f3f3f3;
}
transition: all 0.2s;
&:hover {
  background-color: grey;
  color: white;
  transform: scale(1.01);
}

.rm{
    width: 3rem;
  }

${({ $conceito }) => $conceito == 'apto' ? ` 
    color: #39b700;
    font-weight: 600;
    &:hover {
      color: #8aef5c;
    }
    ` : $conceito == 'inapto' ? `
    color: #dc1e1e;
    font-weight: 600;
    &:hover {
      color: #ff3535;
    }
  ` : ''}

`
export const FormDiv = styled.form`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 1em;
padding-top: 1%;
padding-bottom: 1%;
border-right: 5px solid #1a2e35;
border-left: 5px solid #1a2e35;
`

export const DragAndDropArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease;

  ${({ dragActive }) =>
    dragActive
      ? `
    background-color: #e0f7fa;
    border-color: #00acc1;
  `
      : ""}
`;

export const DragAndDropArea2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 3rem; */
  gap: 3em;
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 15px 60px;
  text-align: center;
  position: relative;
  margin-top: 10px;
  transition: background-color 0.2s ease;

  ${({ dragActive }) =>
    dragActive
      ? `
    background-color: #e0f7fa;
    border-color: #00acc1;
  `
      : ""}
`;

export const HiddenFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const Instructions = styled.p`
  font-size: 16px;
  color: #777;
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 10px; */
  font-size: 16px;
  color: #333;
`;

export const FileDetails = styled.div`
  font-size: 14px;
  color: #555;
`;

export const PdfViewer = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const DivideTable = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  background-color: #1a2e35;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #ffffff;
  gap: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
`;

export const Infos = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: capitalize;
  justify-content: space-around;
  align-items: center;
`;

export const CursoTurmaDiscInfo = styled.div`
  justify-content: center;
  align-items: center;

  p {
  font-size: 0.9rem;
  font-weight: 100;
  text-transform: capitalize;
}
`;

export const Grup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  width: fit-content;

  ::placeholder {
    color: #ffffff;
  }
`;