import React from 'react'
import { Header, Img, Logo, LogoutIcon } from './style'
import LogoFiec from "../../assets/images/fiec-logo.png"
import { useNavigate } from 'react-router-dom'
import { Nav, Bars, NavResponsive, NavMenu, NavLink, Logoperfil } from "././././style.js";
import api from '../../Services/api'
import { Authenticator, AuthenticatorAdmin } from '../../auth'

export default function Navbar(props) {

  const Navigate = useNavigate()
  const token = localStorage.getItem("token")

  function LogVerify() {
    api.get('/autenticar-token', token)
      .then((res) => {
        if (res.data.perfil === "aluno") {
          if (window.location.href.includes('aluno')) {
            // console.log('haha');
            window.location.reload()
          }
          Navigate("/aluno")
        } else if (res.data.perfil === "usuario" || res.data.perfil === "secretaria") {
          if (window.location.href.includes('secretaria')) {
            // console.log('haha');
            window.location.reload()
          }
          Navigate("/secretaria")
        } else {
          Navigate("/")
        }
      }).catch((err) => {
        // console.log("Erro: ", err)
        Navigate("/")
      })
  }

  function Logout() {
    return AuthenticatorAdmin() == true ? (
      <>
        <LogoutIcon
          onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("usuario");
            Navigate("/");
          }}
          title="Sair"
        />
      </>
    ) : Authenticator() == true ? (
      <>
        <LogoutIcon
          onClick={() => {
            localStorage.removeItem("token");
            // sse.close();
            Navigate("/");
          }}
          title="Sair"
        />
      </>
    ) : (null);
  }

  return (
    <div>
      <Header>
        <Logo onClick={() => LogVerify()}>
          <Img src={LogoFiec} alt='LOGO DA FIEC' />
        </Logo>
        <Logout />
        <NavMenu>
          <NavLink onClick={() => window.location.href = "https://alunos.tawk.help"} target={"_blank"}>
            Ajuda ?
          </NavLink>
        </NavMenu>
      </Header>
    </div>
  )
}
