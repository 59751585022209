import React, { useState, useEffect } from "react"
import { Container, FirstDiv, Form, SearchDiv } from "./style"
import Button from "../../Components/Button"
import Input from "../../Components/Input"
import api from "../../Services/api"
import Swal from "sweetalert2"

export default function GerarCarteirinhas() {
  const [RmAluno, setRmAluno] = useState("")
  const [Nome, setNome] = useState("")
  const [DataNascimento, setDataNascimento] = useState("")
  const [DataInicio, setDataInicio] = useState("")
  const [DataTermino, setDataTermino] = useState("")
  const [Email, setEmail] = useState("")
  const [Curso, setCurso] = useState("")

  var rm = RmAluno

  function HandleChange() {
    api
      .get(`/aluno/listar?procurar=${rm}`)
      .then((res) => {
        setNome(res.data[0].nome)
        setDataNascimento(res.data[0].dt_nascimento)
        setDataInicio(res.data[0].dt_inicio)
        setDataTermino(res.data[0].dt_termino)
        setEmail(res.data[0].email)
        setCurso("Informatica")
      })
      .catch((err) => {
        console.log(err)
        Swal.fire("Aluno não encontrado")
      })
  }

  return (
    <Container>
      <FirstDiv>
        <Form>
          <SearchDiv>
            <Input
              type="number"
              required="required"
              placeholder="RM do Aluno"
              color="white"
              event={(e) => {
                setRmAluno(e.target.value)
              }}
            />
          </SearchDiv>
          <Button
            placeholder="Teste"
            width="150px"
            height="50px"
            event={(e) => {
              e.preventDefault()
              HandleChange()
            }}
          />
        </Form>
        <br />
        <br />
        <br />
        <Input placeholder="Nome do Aluno" color="white" value={Nome} readOnly />
        <Input placeholder="Data de Nascimento" color="white" value={DataNascimento} readOnly />
        <Input placeholder="Data de Inicio" color="white" value={DataInicio} readOnly />
        <Input placeholder="Data de Termino" color="white" value={DataTermino} readOnly />
        <Input placeholder="Email" color="white" value={Email} readOnly />
        <Input placeholder="Curso" color="white" value={Curso} readOnly />
      </FirstDiv>
    </Container>
  )
}
