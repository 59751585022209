import { HiTrash, HiOutlinePhotograph } from "react-icons/hi"
import styled, { css, keyframes } from "styled-components"

export const Contanier = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  overflow: scroll;
`;

export const Card = styled.div`
  padding: 10px 2rem 2rem 2rem;
  height: fit-content;
  gap: 15px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);

  @media (max-width: 1000px) {
    padding: 0 1rem 1rem 1rem;
    gap: 5px;
  }
`;
export const Title = styled.h1`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 1.6rem;
  }
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 110%;
  
  @media (max-width: 1200px) {
    width: 80vw;
  }
`;

export const Forms = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    font-size: small;
    gap: 0rem;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20px);
    opacity: 0;
  }
`;

export const AnimatedField = styled.div`
  display: ${({ $show }) => ($show ? 'flex' : setTimeout(() => { 'none' }, 800))};
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  transition: max-height 1s ease-out, opacity 1s ease-out;
  width: ${({ $show }) => ($show ? '100%' : 0)};
  max-height: ${({ $show }) => ($show ? '500px' : 0)};
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  /* animation: ${({ $show }) => ($show ? css`${slideIn} 1s forwards` : css`${slideOut} 1s forwards`)}; */
`;

export const Buttons = styled.button`
  width: 2.5rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
`
export const DropDiv2 = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 100px;
  min-height: 5rem;
  height: fit-content;
  border: 3px dashed grey;
  border-radius: 10px;
  transition: transform 1s;
  transition: color 1s;
  &:hover {
    transform: scale(1.05);
    transition: transform 1s;
    cursor: pointer;
    border: 3px dashed black;
    animation: rotate 2s infinite linear;
  }

  @media (max-width: 1000px) {
    margin: 5px;
    max-width: 70px;
    min-height: 55px;
  }

`
export const ImageContainer = styled.div`
  position: relative;
  border-radius: 10px;
  max-width: 280px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  bottom: 0;
  right: -40px;
  z-index: 10;
  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`
export const FotoIcon = styled(HiOutlinePhotograph)`
  font-size: 80px;
  position: absolute;
  z-index: 0;
  &:hover {
    transform: scale(1);
    color: var(--main-color);
  }

  @media (max-width: 1000px) {
    font-size: 60px;
  }
`
export const Image = styled.img`
  position: relative;
  max-width: 65px;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid black;

  &[alt]:after {
    content: "Não suportado!";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-bg-color);
    color: var(--primary-font-color);
  }

  @media (max-width: 1000px) {
    max-width: 70px;
    max-height: 70px;
    min-height: 55px;
  }
`
export const InputFileManual = styled.input`
  position: relative;
  opacity: 0%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid black;
`