import styled from "styled-components";

export const Page = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

export const DivMain = styled.div`
  width: 90%;
  padding-top: 4rem;
  @media (max-width: 1190px) {
    width: 99%;
    padding: 0 0 1rem 1rem;
  }
`;

export const Legendas = styled.div`
    width: 95%;
    display: flex;
    color: var(--background);
  `

export const Title = styled.h1`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  color: var(--background);
  background-color: #1a2e35;
  margin-right: 1.5%;
  font-weight: 600;
  border-radius: 20px 20px 0 0;
  white-space: nowrap;
  @media (max-width: 1190px) {
    width: 100%;
    margin-right: 0;
    font-size: 1rem;
    border-radius: 0;
    padding: 10px;
  }
`;

const BaseDescription = styled.div`
  display: flex;
  padding: 10px 20px 0 20px;
  align-items: center;
  text-align: center;
  color: var(--background);
  background-color: #1a2e35;
  margin-right: 1%;
  font-weight: 700;
  border-radius: 20px 20px 0 0;
  white-space: nowrap;
  
  p {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    gap: 1em;
  }

  @media (max-width: 1190px) {
    width: 50%;
    font-size: 10px;
    border-radius: 0 20px 0 0;
    padding: 10px;
    margin-right: 0;
  }
`;

export const Description2 = styled(BaseDescription)`
  justify-content: start;
  flex-direction: column;
  font-size: 12px;
  gap: 1em;
`;

export const Description = styled(BaseDescription)`
  text-transform: capitalize;
  flex-direction: column;
  gap: 0.5em;

  p {
    .terminou, .andamento {
      border-radius: 100px;
      padding: 10px;
    }
    .terminou { background-color: #39b700; }
    .andamento { background-color: #dcb02a; }
  }

  @media (max-width: 1190px) {
    p {
      .terminou, .andamento, span {
        padding: 5px;
      }
      span { 
      border-radius: 100px;
      background-color: #eeeeee; 
      }
    }
  }
`;

export const Menus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  @media (max-width: 1190px) {
    flex-direction: column;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  gap: 20px;
  @media (max-width: 1190px) {
    gap: 10px;
  }
`;

export const ContainerTable = styled.div`
  width: 99%;
  min-height: 60vh;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0 0 10px 10px;
  border-top: 5px solid #1a2e35;
  border-bottom: 5px solid #1a2e35;
  @media (max-width: 1190px) {
    width: 95%;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 1190px) {
    display: none;
  }
`;

export const Theader = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: 0;
  height: 8vh;
  font-size: 1rem;
  z-index: 9;
`;

export const Th = styled.th`
  padding: 2px 15px;
  text-align: center;
`;

export const Td = styled.td`
  padding: 4px 30px;
  text-align: start;

  p {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    text-transform: uppercase;
    white-space: break-spaces;
    gap: 0.5em;

    .terminou, .andamento {
      border-radius: 100px;
      padding: 10px;
    }
    .terminou { background-color: #39b700; }
    .andamento { background-color: #dcb02a; }
  }

  ${({ $conceito }) =>
    $conceito === 'apto' || $conceito === 'inapto'
      ? `
        color: ${$conceito === 'apto' ? '#39b700' : 'red'};
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
      `
      : ''
  }

  @media (max-width: 1190px) {
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
    padding: 0;
    font-weight: 700;

    p {
      font-size: 0.7rem;
      .terminou, .andamento {
        padding: 0;
        background-color: #fff;
      }
      .terminou { color: #53a22e; }
      .andamento { color: #dcb02a; }
    }
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
  justify-content: space-around;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 230px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1), 0 0 0 2px rgb(190, 190, 190), 0.3em 0.3em 1em rgba(0,0,0,0.3);
  gap: 10px;
  justify-content: center;

  @media (max-width: 450px) {
    width: 80%;
  }

  .text { font-size: 0.6rem; }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;
    gap: 20px;

    p {
      font-size: 0.8rem;
      text-transform: capitalize;
      font-weight: 800;
    }

    .pdf { height: 1.5rem; }
  }

  ${({ $conceito, $andamento, $terminou }) => `
    ${$conceito ? `
      .conceito {
        width: 100%;
        text-align: center;
        color: ${$conceito === 'apto' ? '#39b700' : 'red'};
        text-transform: uppercase;
        font-weight: 800;
      }
    ` : ''}

    span {
      border-radius: 100px;
      padding: 3px;
      background-color: ${$andamento ? '#dcb02a' :
      $terminou ? '#39b700' :
        '#eeeeee'
    };
    }

    ${$andamento ? `
      div .pdf {
        width: 100%;
        text-align: center;
        color: #f1c232;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 0.7rem;
      }
    ` : ''}

    ${!$andamento && !$terminou ? `
      div .pdf {
        width: 100%;
        text-align: center;
        color: #999999;
        text-transform: uppercase;
        font-weight: 900;
      }
    ` : ''}
  `}
`;

export const TdDisciplina = styled.td`
  padding: 4px 30px;
  text-align: center;
  ${({ $andamento, $terminou }) =>
    $andamento ? 'color: #dcb02a; font-weight: 600;' :
      $terminou ? 'color: #39b700; font-weight: 600;' :
        ''
  }
`;

export const Tbody = styled.tbody`
  height: fit-content;
`;

export const Trbody = styled.tr`
  border-bottom: 1px solid #dddddd;
  height: 50px;
  &:nth-of-type(even) { background-color: #f3f3f3; }
  transition: all 0.2s;
  &:hover {
    background-color: #b0b0b0;
    color: #fff;
    transform: scale(1.01);
  }
  .icon { text-align: center; }
  .conceito {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const DivText = styled.h2`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 700;
  @media (max-width: 1190px) {
    font-size: 1rem;
  }
`;