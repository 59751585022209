import { FaFileImport } from "react-icons/fa"
import styled, { css, keyframes } from "styled-components"
import { HiTrash } from "react-icons/hi"
import { AiOutlineFileImage } from "react-icons/ai"

const moveInR = keyframes`
    from {
         transform: translateX(100%);
    }
    to {
         transform: translateX(0%);
    }
`

const moveInL = keyframes`
  from { 
    transform: translateX(0%);
 }
  to { 
    transform: translateX(100%);
 }
`

export const Container = styled.div`
  margin-top: 2vh;
  display: flex;
  position: relative;
  flex-direction: row;
  height: 84vh;
  width: 100%;
  gap: 0.5%;
  overflow: hidden;
`

export const FirstCard = styled.div`
  margin-top: 0.5vh;
  width: 49.75%;
  max-height: 82vh;
  background-color: var(--azul);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 0px 30px 30px 0px;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.01);
    transition: ease-in-out 0.5s;
  }
`

export const SecondCard = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 90;
  background-color: var(--background);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 30px 0px 0px 30px;
  height: 90%;
  width: 100%;
  overflow: hidden;
  animation: ${(props) =>
    props.Estado == true
      ? css`
          ${moveInR} 1.0s;
        `
      : css`
          ${moveInL} 1.0s;
        `};
`

export const InputFileManual = styled.input`
  position: relative;
  opacity: 0%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid black;

  &:hover {
    cursor: pointer;
  }
`
export const Paragrafo = styled.p`
  font-weight: "bold";
  text-align: center;
  font-size: 1.1rem;
`

export const InputFile = styled.input`
  /* position: absolute; */
  opacity: 0%;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid black;
  width: 100%;
  height: 100%;
  border-radius: 70px;
  z-index: 15;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    width: 30%;
    height: 31rem;
  }
`

export const Spacediv = styled.div`
  height: 93vh;
`

export const ImageContainer = styled.div`
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
`

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

  &[alt]:after {
    content: "Não suportado!";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: var(--secondary-bg-color);
    color: var(--primary-font-color);
  }
`

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  margin-top: 27rem;
  margin-right: -25rem;
  z-index: 16;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`

export const DropDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% auto;
  margin-top: 3%;
  width: 30%;
  height: 60%;
  border: 3px dashed grey;
  border-radius: 70px;
  transition: transform 1s;

  &:hover {
    transform: scale(1.05);
    transition: transform 1s;
    cursor: pointer;
    border: 3px dashed var(--azul);
    animation: rotate 2s infinite linear;
  }
`

export const DropDiv2 = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 10%;
  height: 10%;
  border: 3px dashed grey;
  border-radius: 20px;
  transition: transform 1s;
  transition: color 1s;

  &:hover {
    transform: scale(1.05);
    transition: transform 1s;
    cursor: pointer;
    border: 3px dashed white;
    animation: rotate 2s infinite linear;
  }
`

export const Field = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5em;
  border-radius: 25px;
  padding: 0.6em;
  outline: none;
  color: var(--background);
  /* border: 1px solid black; */
  width: 60%;
  background-color: transparent;
`

export const Mfield1 = styled.div`
  /* border: 1px solid black; */
`

export const Mfield2 = styled.div`
  /* border: 1px solid black; */
`

export const Heading = styled.div`
  display: block;
  /* margin-left: auto; */
  /* margin-right: 5vh; */
  white-space: nowrap;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 5vh;
  color: var(--background);
  /* width: 35%; */
  font-size: 4em;
  background-color: transparent;
  border-radius: 20px;
  font-family: Dancing Script;
  /* border: 1px solid black; */
`

export const Heading2 = styled.div`
  display: block;
  /* margin-left: auto; */

  white-space: nowrap;
  text-align: center;
  /* margin-bottom: 1.5em; */
  margin-top: 1vh;
  color: var(--azul);
  /* width: 35%; */
  font-size: 4em;
  background-color: transparent;
  border-radius: 20px;
  font-family: Dancing Script;
  /* border: 1px solid black; */
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  /* justify-items: center; */
  align-items: center;
  /* border: 1px solid black; */
  height: 89vh;
`
export const HeaderDiv = styled.div`
  width: 100%;
  height: 22%;
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
`

export const IconeDive = styled.div`
  width: 10%;
  height: 20%;
  align-items: center;
  display: flex;
  margin-top: 1%;
  cursor: pointer;
  gap: 1rem;
`
export const Iconview = styled(AiOutlineFileImage)`
  font-size: 2.5rem;
`
export const Icon = styled(FaFileImport)`
  position: absolute;
  z-index: 9;
  font-size: 10rem;
  color: grey;
  transition: color 0.5s;
  ${DropDiv}:hover & {
    color: var(--azul);
  }
`

export const Button1 = styled.button`
  font-size: larger;
  border: none;
  background-color: var(--azul);
  margin-left: 40%;
  margin-right: 8vh;
  color: var(--background);
  border-radius: 15px;
  width: 150px;
  height: 50px;
  transition: transform 1s;
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);

  &::before {
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  }

  &:hover {
    transform: scale(1.05);
    border: 1px solid var(--azul);
    cursor: pointer;
    color: var(--azul);
    background-color: var(--background);
  }
`

export const Button2 = styled.button`
  font-size: larger;
  border: none;
  background-color: var(--azul);
  color: var(--background);
  border-radius: 15px;
  width: 150px;
  height: 50px;
  transition: transform 0.5s;
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);

  &::before {
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    border: 1px solid var(--azul);
    color: var(--azul);
    background-color: var(--background);
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid black;
  max-width: 400px;
  // padding: 10px 0;
`

export const ButtonsDiv = styled.div`
  // border: 1px solid red;
`

export const Imagecsv = styled.div`
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  z-index: 0;
  color: grey;
  transition: color 0.5s;
  `

export const Arroba = styled.button`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-top: 1%;
  margin-left: 1%;
  align-self: start;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
`
