import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Container,
  ContainerTable,
  Menus,
  Table,
  Theader,
  Trbody,
  Td,
  Th,
  Title,
  Buttons,
  Grup,
  Tbody,
  Boxdiv,
  Mostrando,
  TdNome,
} from "./style";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import Pesquisar from "../../Components/Pesquisar";
import CheckBox from "../../Components/Checkbox";
import api from "../../Services/api";
import { format } from "date-fns";
import { Alert } from "../../utils/Alert";

export default function DesativarAluno() {
  const navigate = useNavigate();
  const [alunos, setAlunos] = useState([]);
  const [ativos, setAtivos] = useState([]);
  const [desativarAluno, setDesativarAluno] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [allCheckedAtivos, setAllCheckedAtivos] = useState(false);
  const [allCheckedDesativados, setAllCheckedDesativados] = useState(false);
  const [allCheckedFiltrados, setAllCheckedFiltrados] = useState(false);
  const [allCheckedVencido, setAllCheckedVencido] = useState(false);
  const [searchRM, setSearchRM] = useState("");
  const [searchNome, setSearchNome] = useState("");
  const [searchCurso, setSearchCurso] = useState("");
  const [searchConvenio, setSearchConvenio] = useState("");
  const [searchDataInicio, setSearchDataInicio] = useState("");
  const [searchDataFim, setSearchDataFim] = useState("");
  const [mostrarTodos, setMostrarTodos] = useState(250);
  const [loading3, setLoading3] = useState("Aplicar alterações");
  const limite = 0.30  // 30%

  useEffect(() => {
    ListarAlunos();
  }, []);
  useEffect(() => {
    handleAllCheckedVencidos();
  }, [allCheckedVencido]);

  function ListarAlunos() {
    api
      .get("/aluno/listar?procurar")
      .then(async (response) => {
        const alunosData = await response.data;
        const alunosAtivos = alunosData.filter((aluno) => aluno.ativo === 1);
        setAlunos(alunosData);
        setAtivos(alunosAtivos);
        setMostrarTodos(alunosData.length * limite)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const desativaAluno = (id, rm) => {
    setDesativarAluno((prevState) =>
      prevState.some((aluno) => aluno.id === id && aluno.rm === rm)
        ? prevState.filter((aluno) => aluno.id !== id || aluno.rm !== rm)
        : [...prevState, { id, rm }]
    );
  };

  const handleAllChecked = () => {
    setAllChecked(!allChecked);
    setDesativarAluno(!allChecked ? alunos.map((aluno) => ({ id: aluno.id, rm: aluno.rm })) : []);
  };

  const handleAllCheckedFiltrados = () => {
    setAllCheckedFiltrados(!allCheckedFiltrados);
    setDesativarAluno(!allCheckedFiltrados ? filteredAlunos.map((aluno) => ({ id: aluno.id, rm: aluno.rm })) : []);
  };

  const handleAllCheckedDesativados = () => {
    setAllCheckedDesativados(!allCheckedDesativados);
    if (!allCheckedDesativados) {
      // Selecionar apenas os alunos desativados
      const alunosDesativados = filteredAlunos.filter((aluno) => aluno.ativo === 0).map((aluno) => ({ id: aluno.id, rm: aluno.rm }));
      setDesativarAluno(alunosDesativados);
    } else {
      setDesativarAluno([]);
    }
  };

  const handleAllCheckedAtivados = () => {
    setAllCheckedAtivos(!allCheckedAtivos);
    if (!allCheckedAtivos) {
      // Selecionar apenas os alunos Ativados
      const alunosAtivados = filteredAlunos.filter((aluno) => aluno.ativo === 1).map((aluno) => ({ id: aluno.id, rm: aluno.rm }));
      setDesativarAluno(alunosAtivados);
    } else {
      setDesativarAluno([]);
    }
  };

  async function handleAllCheckedVencidos() {
    if (allCheckedVencido === false) {
      await ListarAlunos();
      setMostrarTodos(alunos.length);
    } else {
      const TODAY = new Date();
      const alunosTermino = alunos.filter((aluno) => new Date(aluno.dt_termino) < TODAY);
      setAlunos(alunosTermino);
      setMostrarTodos(alunosTermino.length);
    }
  }


  async function AplicarAlteracao() {
    setLoading3("Aplicando alterações...");
    try {
      await Promise.all(
        desativarAluno.map((aluno) =>
          api.put(`/aluno/atualizar-status?id_aluno=${aluno.id}&rm=${aluno.rm}`)
        )
      );
      window.location.reload();
    } catch (err) {
      console.log(err);
      Alert("Error", "Ocorreu um erro ao desativar aluno");
    }
  }

  useEffect(() => {
    if (searchRM.length > 0 || searchNome.length > 0 || searchDataInicio.length > 0 ||
      searchDataFim.length > 0 || searchCurso.length > 0 || searchConvenio.length > 0) {
      setMostrarTodos(10000)
    } else {
      setMostrarTodos(filteredAlunos.length * limite)
    }
  }, [searchRM, searchNome, searchDataInicio, searchDataFim, searchCurso, searchConvenio]);

  const normalizeString = (str) => {
    if (str === undefined || str === null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredAlunos = alunos.filter((aluno) => {
    const searchRMNormalized = normalizeString(searchRM);
    const searchNomeNormalized = normalizeString(searchNome);
    const searchCursoNormalized = normalizeString(searchCurso);
    const searchConvenioNormalized = normalizeString(searchConvenio);
    const searchDataInicioNormalized = normalizeString(searchDataInicio);
    const searchDataFimNormalized = normalizeString(searchDataFim);

    const alunoRM = normalizeString(aluno.rm?.toString() || "");
    const alunoNome = normalizeString(aluno.nome_aluno || "");
    const alunoCurso = normalizeString(aluno.nome_curso || "");
    const alunoConvenio = normalizeString(aluno.convenio || "");
    const alunoDataInicio = normalizeString(aluno.dt_inicio ? format(new Date(aluno.dt_inicio), "dd/MM/yyyy") : "");
    const alunoDataFim = normalizeString(aluno.dt_termino ? format(new Date(aluno.dt_termino), "dd/MM/yyyy") : "");

    return (
      alunoRM.includes(searchRMNormalized) &&
      alunoNome.includes(searchNomeNormalized) &&
      alunoCurso.includes(searchCursoNormalized) &&
      alunoConvenio.includes(searchConvenioNormalized) &&
      alunoDataInicio.includes(searchDataInicioNormalized) &&
      alunoDataFim.includes(searchDataFimNormalized)
    );
  }).slice(0, mostrarTodos);

  return (
    <Container>
      <Menus>
        <Title>Desativar Alunos</Title>
        <Buttons>
          <Button
            event={() => {
              navigate("/secretaria");
            }}
            width="12%"
            margintop="2%"
            placeholder="Voltar"
            backgroundcolor="#1a2e35"
            borderRadius="30px 30px 0px 0px"
          />
          {searchCurso.length || searchRM.length || searchNome.length || searchDataFim.length || searchDataInicio.length ? (
            <Boxdiv>
              <CheckBox
                cor={"white"}
                borda={"#ffffffce"}
                weight={"bold"}
                size={"13px"}
                label="Selecionar Filtrados"
                checked={allCheckedFiltrados}
                event={handleAllCheckedFiltrados}
              />
            </Boxdiv>
          ) : (
            <>
              {allCheckedVencido == false ? (
                <Button
                  event={() => {
                    setAllCheckedVencido(true);
                  }}
                  width="12%"
                  margintop="2%"
                  backgroundcolor="#1a2e35"
                  borderRadius="30px 30px 0px 0px"
                  placeholder="Ver Alunos Vencidos"
                />
              ) : (
                <Button
                  event={() => {
                    setAllCheckedVencido(false);
                  }}
                  width="12%"
                  margintop="2%"
                  backgroundcolor="#1a2e35"
                  borderRadius="30px 30px 0px 0px"
                  placeholder="Ver Todos Alunos"
                />
              )}
            </>
          )}
          <Boxdiv>
            <CheckBox
              cor={"white"}
              borda={"#ffffffce"}
              weight={"bold"}
              size={"13px"}
              label="Selecionar Ativos"
              checked={allCheckedAtivos}
              event={handleAllCheckedAtivados}
            />
          </Boxdiv>
          <Boxdiv>
            <CheckBox
              cor={"white"}
              borda={"#ffffffce"}
              weight={"bold"}
              size={"13px"}
              label="Selecionar Desativados"
              checked={allCheckedDesativados}
              event={handleAllCheckedDesativados}
            />
          </Boxdiv>
          {allCheckedVencido == true || searchRM.length || searchNome.length
            || searchCurso.length || searchConvenio.length ? null :
            (
              <>
                {mostrarTodos < alunos.length ? (
                  <Button
                    event={() => setMostrarTodos(alunos.length)}
                    width="15%"
                    margintop="2%"
                    backgroundcolor="#1a2e35"
                    placeholder={"Mostrar Todos"}
                    borderRadius="30px 30px 0px 0px"
                    fontsize={'13px'}
                  />
                ) : (
                  <Button
                    event={() => setMostrarTodos(alunos.length * limite)}
                    width="15%"
                    margintop="2%"
                    backgroundcolor="#1a2e35"
                    placeholder={"Reduzir Alunos"}
                    borderRadius="30px 30px 0px 0px"
                    fontsize={'13px'}
                  />
                )}
              </>
            )}
        </Buttons>
        <Button
          event={AplicarAlteracao}
          width="10%"
          margintop="1%"
          marginright="2%"
          backgroundcolor="#2c3b41"
          placeholder={loading3}
          borderRadius="30px 30px 0px 0px"
          fontsize={'13px'}
        />
      </Menus>
      <ContainerTable>
        <Table>
          <Theader>
            <tr>
              <Th>
                <Grup>
                  <Pesquisar
                    color="white"
                    placeholder="RM"
                    name="rm"
                    value={searchRM}
                    event={(e) => setSearchRM(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    color="white"
                    placeholder="Nome"
                    name="nome"
                    value={searchNome}
                    event={(e) => setSearchNome(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    color="white"
                    placeholder="Curso"
                    name="curso"
                    value={searchCurso}
                    event={(e) => setSearchCurso(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    color="white"
                    placeholder="Convênio"
                    name="convenio"
                    value={searchConvenio}
                    event={(e) => setSearchConvenio(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th style={{ color: "#a8a8a8", marginRight: "10rem" }}>Data de Início</Th>
              <Th style={{ color: "#a8a8a8", marginRight: "10rem" }}>Data de Término</Th>
              <Th>
                <Grup>
                  <Mostrando>Mostrando: {filteredAlunos.length}</Mostrando>
                  <Mostrando>Selecionados: {desativarAluno.length}</Mostrando>
                </Grup>
              </Th>
            </tr>
          </Theader>
          <Tbody>
            {filteredAlunos.map((d, index) => (
              <Trbody key={index}>
                {d?.ativo == 0 ? (
                  <>
                    <Td style={{ color: 'red' }}>{d?.rm}</Td>
                    <TdNome style={{ color: 'red' }}>{d?.nome_aluno}</TdNome>
                    <Td style={{ color: 'red' }}>{d?.nome_curso}</Td>
                    <Td style={{ color: 'red' }}>{d?.convenio}</Td>
                    <Td style={{ color: 'red' }}>{format(new Date(d?.dt_inicio), "dd/MM/yyyy")}</Td>
                    <Td style={{ color: 'red' }}>{format(new Date(d?.dt_termino), "dd/MM/yyyy")}</Td>
                    <Td>
                      <CheckBox
                        label="Ativar Aluno"
                        cor={'red'}
                        checked={desativarAluno.some((selectedAluno) => selectedAluno.id === d.id && selectedAluno.rm === d.rm)}
                        event={() => desativaAluno(d.id, d.rm)}
                      />
                    </Td>
                  </>
                ) : (
                  <>
                    <Td>{d.rm}</Td>
                    <TdNome>{d.nome_aluno}</TdNome>
                    <Td>{d.nome_curso}</Td>
                    <Td>{d.convenio}</Td>
                    <Td>{format(new Date(d.dt_inicio), "dd/MM/yyyy")}</Td>
                    <Td>{format(new Date(d.dt_termino), "dd/MM/yyyy")}</Td>
                    <Td>
                      <CheckBox
                        label="Desativar Aluno"
                        checked={desativarAluno.some((selectedAluno) => selectedAluno.id === d.id && selectedAluno.rm === d.rm)}
                        event={() => desativaAluno(d.id, d.rm)}
                      />
                    </Td>
                  </>
                )}
              </Trbody>
            ))
              // .slice(0, mostrarTodos)
            }
          </Tbody>
        </Table>
      </ContainerTable>
    </Container>
  );
}
