import styled from "styled-components"

export const Contanier = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
`
export const Card = styled.div`
  width: 15%;
  padding: 2%;
  gap: 10px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
  @media (max-width: 1200px) {
    width: 30%;
    padding: 3%;
    }
  @media (max-width: 700px) {
    width: 50%;
    }
  @media (max-height: 500px) {
    width: 25%;
    padding: 2%;
    }
`
export const Title = styled.h1`
  font-size: 1.8rem;
  margin-top: 6%;
  font-weight: 300;
  text-align: center;
  margin-left: 20%;
  margin-right: 5%;
  @media (max-width: 700px) {
    margin-left: 5%;
    margin-right: 0;
    font-size: 1.6rem;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 80%;
  height: 5%;
`

export const Buttons = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* margin-top: 0%;
  margin-left: 5%;
  margin-right: 5%;
  cursor: pointer; */
  border: none;
  background-color: transparent;
  /* transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
  @media (max-width: 700px) {
    margin-left: 1%;
    margin-right: 5%;
  } */
`
