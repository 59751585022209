import React, { useEffect, useState } from "react"
import "./style.css"

import logo from "../../../assets/images/logocarterinha.png"
import userDefault from "../../../assets/images/user.png"
import ass from "../../../assets/images/ass.png"

export default function CarterinhaMobileS() {
  const [alunoData, setAlunoData] = useState([])
  const [user, setUser] = useState("")

  const locale = "pt-BR"
  let imageUrl = ""

  const Info = localStorage.getItem("carterinhaInfo")
  const userData = JSON.parse(Info);
  return (
    <div className="global">
      <div className="circle"></div>
      <div className="quadros">
        <img src={userData.foto} alt="perfil" className="perfilfoto" />
      </div>
      <div className="infocard">
        <h1 className="names">{userData.perfil}{userData.nome}</h1>
        <div className="cardsss">
          <div className="columns1">
            <p>Curso</p>
            <p style={{ textTransform: "uppercase", fontSize: 12, fontWeight: "800", whiteSpace: "nowrap" }}>
              {userData.curso}
            </p>
          </div>
          <div className="columns1">
            <p>Validade</p>
            <p style={{ color: "red", fontWeight: "700" }}>
              {new Date(userData.validade).toLocaleDateString(locale)}
            </p>
          </div>
        </div>
      </div>
      <div className="imgames">
        <img src={logo} alt="logo" className="logofiec" />
        <img src={ass} alt="assinatura" className="assinatura" />
      </div>
      <div className="logoname">
        <p style={{ fontWeight: "600", marginLeft: "4%" }}>Prefeitura Municipal De Indaiatuba</p>
      </div>
      <p className="paragrafos">
        Este cartão é de uso estritamente pessoal e intransferível. O acesso a escola somente será permitido mediante
        apresentação desse cartão ou autorização da secretaria.
      </p>
      <div className="convenio" style={{ backgroundColor: userData.cor_convenio }}>
        <p className="expo">{userData.convenio}</p>
      </div>
    </div>
  )
}
