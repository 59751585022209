import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  /* display: grid;
  grid-template-columns: repeat(3,400px);
  column-gap: 2%; */
  align-items: center;
  justify-content: center;
  z-index: 6;
`
export const Card = styled.div`
  display: flex;
  width: fit-content;
  max-width: 55vw;
  height: fit-content;
  max-height: 75vh;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  padding: 1%;
  padding-right: 3%;
  padding-left: 3%;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 15px 80px rgba(0, 0, 0, 0.8);
   /*
   --border-width: 8px;
	overflow: hidden;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      #3e86f0,
      #3e86f0,
      #3e86f0,
      #3e86f0,
      #f2d100,
      #f2d100,
      #f2d100,
      #f2d100
    );
    background-size: 300% 300%;
    background-position: 0 100%;
    border-radius: calc(2 * var(--border-width));
    animation: rotate 4s linear infinite;
  }
  @keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
} 
*, *::before, *::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

	z-index: 0;
	overflow: hidden;
	font-weight: 600;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#ffff40, #003cb7);
		animation: rotate 8s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 5px;
	} */

  @media (max-width: 1000px) {
    font-size: 1rem;
    padding: 5%;
    max-width: 75vw;
    max-height: 95vh;
  }
`
export const Title = styled.h1`
  font-weight: 600;
  text-align: center;
  color: #D90000;
  @media (max-width: 700px) {
    font-size: 1.5rem;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10%;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
`
export const Content = styled.p`
  align-items: center;
  justify-content: center;
`

export const Buttons = styled.div`
  display: flex;
  width: 50%;
  height: 15%;
  justify-content: center;
  align-items: end;
  border: none;
  background-color: transparent;
  transition: 0.4s ease-in-out;
  @media (max-width: 1000px) {
    width: 50%;
    padding: 2%;
  }
`
