import { SlActionUndo } from "react-icons/sl";
import { FaFileImport } from "react-icons/fa"
import { RiArrowGoBackFill } from "react-icons/ri";
import { HiTrash } from "react-icons/hi"
import { RxReset } from "react-icons/rx";
import styled, { keyframes } from "styled-components"

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.Show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 998;

  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--blur-bg-color);
    position: fixed;
    top: 0;
    left: 0;
  }
`

export const Close = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  padding: 20px 0px 0px 50px;
  transition: transform 0.2s;
  // border: 1px solid black;
`

export const Title = styled.div`
  padding: 10px;
  text-align: center;
  color: var(--azul);
  font-size: 2.5rem;
  font-weight: bold;
  padding: 30px;
`

export const Container = styled.div`
  z-index: 999;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 2vh;
  height: fit-content;
  width: fit-content;
  min-width: 60vh;
  max-width: 80vh;
  background-color: var(--background);
  gap: 0.5%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`

export const DivMain = styled.div`
  display: flex;
  position: relative;
  gap: 20px;
  flex-direction: column;
  width: 75%;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
`
export const PreviewImage = styled.img`
  width: 8rem;
  border: 1px solid black;
  border-radius: 10px;
`

export const DivContent = styled.div`
  min-width: 30rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px;
  gap: 10px;
  justify-content: space-between;
  border: 2px dotted black;
  border-radius: 10px;
  `
export const Header = styled.p`
  color: #00085c;
  text-align: start;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
`
export const Content = styled.p`
  color: black;
  text-align: end;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: capitalize;
`