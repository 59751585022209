import { FaFileImport } from "react-icons/fa";
import { HiTrash } from "react-icons/hi";
import styled from "styled-components";


export const Background = styled.div`
width: 100vw;
height: 100vh;
display: flex;
position: absolute;
align-items: center;
 justify-content: center;
background-color: rgba(0,0,0,0.65);
z-index: 10;
top: 0;
/* border: 15px solid black; */
`

export const Cardo = styled.div`
width: 80vw;
height: 90vh;
display: flex;
align-items: center;
flex-direction: column;
background-color: white;
border: 1px solid black;
border-radius: 20px;
padding: 20px 30px;
`
export const DivSave = styled.div`
width: 100%;
height: 12%;
display: flex;
justify-content: space-between;
align-items: end;
padding-top: 10px;
`

export const DivMain = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 5px solid #1a2e35;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
export const DivTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  font-weight: 400;
  border-radius: 20px 20px 0px 0px;
  border-right: 5px solid #1a2e35;
  border-left: 5px solid #1a2e35;
`

export const Title = styled.h1`
  padding: 15px 50px 15px 30px;
`

export const Menus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`
export const DivTable = styled.div`
display:flex;
flex-direction: column;
height: 100%;
width: 100%;
justify-content:center;
align-items:center;
overflow: hidden;
`

export const DivText = styled.h2`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items:center;
`
export const ContainerTable = styled.div`
  width: 100%;
  min-height: 70%;
  overflow-x: hidden;
  overflow-y: auto;
  border: 5px solid #1a2e35;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
`

export const Theader = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: 0;
  height: 8vh;
  font-size: 1rem;
  z-index: 9;
`

export const Th = styled.th`
padding: 2px 10px;
text-align: center;
`

export const Td = styled.td`
padding: 10px 30px;
min-height: 20vh;
text-align: center;
`

export const Tbody = styled.tbody`
height: fit-content;
overflow: scroll;
`

export const Trbody = styled.tr`
border-bottom: 1px solid #dddddd;
height: 50px;
&:nth-of-type(even) {
  background-color: #f3f3f3;
}

transition: all 0.2s;

&:hover {
  background-color: grey;
  color: white;
  transform: scale(1.01);
}
`
export const FormDiv = styled.form`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
gap: 3em;
padding-top: 2%;
padding-bottom: 2%;
border-right: 5px solid #1a2e35;
border-left: 5px solid #1a2e35;
`
export const ButtonSearch = styled.button`
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: white;
  z-index: 1;
  background: #1a2e35;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  &:hover::before {
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: var(--amarelo);
    z-index: -1;
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms;
  }

  &:active::before {
    background-color: var(--azul);
  }

  &:placeholder-shown {
    font-size: 17px;
    cursor: text;
    top: 20px;
    color: var(--background);
  }

  &:hover {
    color: #000;
  }
`;
//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const DragAndDropArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease;

  ${({ dragActive }) =>
    dragActive
      ? `
    background-color: #e0f7fa;
    border-color: #00acc1;
  `
      : ""}
`;

export const DragAndDropArea2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 3rem; */
  gap: 3em;
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 15px 60px;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  transition: background-color 0.2s ease;

  ${({ dragActive }) =>
    dragActive
      ? `
    background-color: #e0f7fa;
    border-color: #00acc1;
  `
      : ""}
`;

export const HiddenFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const Instructions = styled.p`
  font-size: 16px;
  color: #777;
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 10px; */
  font-size: 16px;
  color: #333;
`;

export const FileDetails = styled.div`
  font-size: 14px;
  color: #555;
`;

export const DeleteButton = styled.button`
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e53935;
  }
`;

export const UploadButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const PdfViewer = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;