import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column; 
    justify-content: flex-start;
    gap: 1.5rem;
  }
`

export const CardBox = styled.div`
  width: 10vw;
  height: 10vh;
  background-color: black;
`

export const Buttons = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vw;
`
export const ContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15%;
`

export const Menu = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`
