import React, { useEffect, useState } from 'react';
import {
  Background, DivSave, Title, Cardo, ContainerTable, Table, Theader, Th, Tbody, Trbody, Td, DivMain, FormDiv, PdfViewer,
  DragAndDropArea, HiddenFileInput, FileInfo, FileDetails, Instructions, DragAndDropArea2, DivTitle, DivideTable, Infos,
  CursoTurmaDiscInfo, Theader2, DivText, DivText2, ThRm, Grup, ThNome
} from './style';
import './styleCard.css';
import Button from '../../Button';
import { Url_Pdf, Url_Pdf_Info } from '../../../assets/URL\'S/Url_Pdf';

const CardVerPdfProgressao = ({ Show, setShow, progressaoEdit }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileSize, setFileSize] = useState({ bytes: null, kb: null, mb: null, gb: null });
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!Show) return; // Adicione essa verificação para evitar a execução desnecessária do hook
    const urlpdf = Url_Pdf(progressaoEdit.pdf);
    const urlpdfInfo = Url_Pdf_Info(progressaoEdit.pdf);
    setFileUrl(urlpdf);
    fetch(urlpdfInfo)
      .then(response => response.json())
      .then(data => {
        setFileSize({
          bytes: data.fileSizeInBytes,
          kb: data.fileSizeInKB,
          mb: data.fileSizeInMB,
          gb: data.fileSizeInGB
        });
      })
      .catch(error => {
        console.error('Erro ao fazer a requisição', error);
      });
  }, [Show, progressaoEdit]);

  if (!Show) return null;

  const PC = windowSize.width >= 1000;
  const Poket = windowSize.width <= 600;

  function Fechar(e) {
    e.preventDefault();
    setShow(false);
  }

  return (
    <Background>
      <Cardo>
        <DivTitle><Title>PDF Progressão Parcial</Title></DivTitle>
        {fileUrl && <DivMain><PdfViewer src={fileUrl} type="application/pdf" title="PDF Viewer" /></DivMain>}
        <DivSave>
          <Button
            event={Fechar}
            width={Poket ? '4rem' : '10rem'}
            placeholder={"fechar"}
            backgroundcolor="#1a2e35"
            borderRadius="10px"
            padding={!PC && '10px'}
            fontSize={!PC && '12px'}
          />
          {fileUrl && (
            <DragAndDropArea2>
              <FileInfo>
                <div><span>nome:</span> {progressaoEdit.pdf}.PDF</div>
                {fileSize.kb &&
                  <FileDetails>
                    <span>Tamanho do arquivo:</span> ({fileSize.kb} KB, {fileSize.mb} MB{fileSize.gb > 0.01 ? `, ${fileSize.gb} GB` : ''})
                  </FileDetails>
                }
              </FileInfo>
            </DragAndDropArea2>
          )}
        </DivSave>
      </Cardo>
    </Background>
  )
}

export default CardVerPdfProgressao;
