import styled from "styled-components"
import { FiLogOut } from "react-icons/fi";
import { Authenticator, AuthenticatorAdmin } from "../../auth";
import { NavLink as Link } from 'react-router-dom';

export const Header = styled.header`
  background-color: var(--background);
  width: 100%;
  max-width: 100%;
  /* overflow-x: hidden;
  overflow-y: hidden; */
  height: 10vh;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`

export const Logo = styled.div`
  height: 100%;
  position: auto;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    cursor: pointer;
    transition: transform 0.3s;
    transform: scale(1.05);
  }
`
export const NavLink = styled(Link)`
    margin: 0 .25rem;
    text-decoration: none;
    padding: .6rem .8rem;
    /* border-radius: 0.25rem; */
    color: Red;
    font-weight: bold;
    padding: 0 15px 0 10px;
    cursor: pointer;
    height: 100%;
    font-family: 'Mulish', sans-serif;
    transition: .3s linear;

    &:hover{
        color: whitesmoke;
        font-size:17px;
        background:#e11c1c;
    }

    &.active{
        color: red;
        font-size:17px;
        background:#ffffff;
    }

    @media screen and (max-width: 768px){
        margin: 0 0 1.5rem .25rem;

        &:hover{
            background: transparent;
            color: #ffffff;
            color: white;
            font-size:17px;
        }

        &.active{
            background: transparent;
            color: #e11c1c;
            color: red;
            font-size:17px;
        }
    }
`;

export const NavMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px){
        flex-flow: column nowrap;
        align-items: flex-start;
        margin: 1rem 0 0 0.5rem;
    }
`;

export const LogoutIcon = styled(FiLogOut)`
  cursor: pointer;
  font-size: 25px;
  margin-right: 0.5rem;
  margin-left: -3vh;
  color: black;
  transition: transform 0.2s;
  &:hover {
    color: red;
    transform: scale(1.1);
  }
`

export const Img = styled.img`
  object-fit: cover;
  position: auto;
  margin-left: auto;
  margin-right: auto;
  height: 60%;
  margin-top: 1rem;
`
