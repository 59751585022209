import React, { useState } from "react";
import { Buttons, Card, Contanier, DivButtons, DivMain, Header, Title } from "./style";
import Button from "../../../Button";
import Img1 from "../../../../assets/images/telaLogin/1.png";
import Img2 from "../../../../assets/images/telaLogin/2.png";
import Img3 from "../../../../assets/images/telaLogin/3.png";
import Img4 from "../../../../assets/images/telaLogin/4.png";
import Img5 from "../../../../assets/images/telaLogin/5.png";
import Img6 from "../../../../assets/images/telaLogin/6.png";

export default function ComoAcessar({ showComoAcessar, setShowComoAcessar }) {
  const [steps, setSteps] = useState(0);
  const [isClosing, setIsClosing] = useState(false); // Novo estado para controle de fechamento

  const handleClose = () => {
    setIsClosing(true); // Ativa a animação de fechamento
    setTimeout(() => {
      setShowComoAcessar(false); // Fecha o modal após a animação
      setSteps(0); // Reseta os passos
      setIsClosing(false); // Reseta o estado de fechamento
    }, 500); // Duração da animação em ms (mesma duração da animação definida no CSS)
  };

  if (showComoAcessar || isClosing) {
    return (
      <Contanier>
        <Card $show={showComoAcessar} $closing={isClosing}>
          <Header>
            <Title>Primeiro Acesso</Title>
          </Header>
          {steps === 0 ? (
            <DivMain>
              <h4><span /><p>Preencha o primeiro campo com seu RM:</p></h4>
              <div className="DivImg">
                Exemplo: <img src={Img1} alt="Exemplo" />
              </div>
              <h4><span />No campo senha, entre com sua DATA DE NASCIMENTO:</h4>
              <div className="DivImg">
                Exemplo: <img src={Img2} alt="Exemplo" />
              </div>
            </DivMain>
          ) : steps === 1 ? (
            <DivMain $w={"50%"}>
              <h4><span /><p>Após entrar, será necessário definir sua nova senha: </p></h4>
              <div className="DivImg">
                Antes: <img src={Img3} alt="Exemplo" />
              </div>
              <hr style={{ width: '80%' }} />
              <div className="DivImg">
                Depois: <img src={Img4} alt="Exemplo" />
              </div>
              <hr style={{ width: '80%' }} />
              <DivMain $w={"100%"}>
                <h4><span />Após escrever sua nova senha leia e aceite os termos e clique em enviar!</h4>
                <div className="DivImg"><img src={Img5} alt="Exemplo" /></div>
              </DivMain>
            </DivMain>
          ) : steps === 2 && (
            <DivMain $w={"85%"}>
              <h4><span />Depois de definir sua nova senha e aceitar os termos voltará para tela de login! <br />
                Digite seu RM novamente e sua nova senha! Faça o recaptcha e entre!👍
              </h4>
              <div className="DivImg"><img src={Img6} alt="Exemplo" /></div>
            </DivMain>
          )}
          <DivButtons $steps={steps}>
            {steps > 0 && (
              <Button
                id={"button"}
                event={() => setSteps(steps - 1)}
                marginleft="2rem"
                placeholder="Voltar"
                marginBottom="40px"
              />
            )}
            {steps < 2 ? (
              <Button
                id={"button"}
                event={() => setSteps(steps + 1)}
                marginright="2rem"
                placeholder="Próximo"
                marginBottom="40px"
              />
            ) : (
              <Button
                id={"button"}
                event={handleClose} // Chama a função de fechamento
                marginright="2rem"
                placeholder="Fechar"
                marginBottom="40px"
              />
            )}
          </DivButtons>
        </Card>
      </Contanier>
    );
  }
  return null;
}
