import React from 'react'
import "./style.css"

function Loading() {
    return (
        <div className='loading' />
    )
}

export default Loading
