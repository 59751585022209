import Swal from "sweetalert2"
import api from "./api"
import ResponseCsv from "../Components/modals/StatusXslx"
import { useState } from "react"

export async function SendAnexoApi(data, File) {
  const formData = new FormData()
  
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  formData.append("file", File)

  const config = {
    headers: { "content-type": "multipart/form-data" },
  }

  try {
    const res = await api.post(`/aluno/planilha-alunos`, formData, config)
    const data = res.data
    // console.log('SendAnexoApi data ->', data);

    const aux = {
      mensagem: data.mensagem,
      num_alunos: data.descricao.num_alunos,
      sucessos_cadastro: data.descricao.sucessos_cadastro,
      nao_cadastrados: data.descricao.nao_cadastrados,
      nome_nao_cadastrados: data.resultado.erro.nome_nao_cadastrados,
      rm_duplicado: data.descricao.rm_duplicado,
      falta_info: data.descricao.falta_info,
      rm_invalido: data.descricao.rm_invalido,
      data_nasc: data.descricao.data_nasc,
      data_inic: data.descricao.data_inic,
      data_term: data.descricao.data_term,
      buscar_curso: data.descricao.buscar_curso,
      erro_cadastro: data.descricao.erro_cadastro,
      erro_servidor: data.descricao.erro_servidor,
      rm_nao_cadastrados: data.resultado.erro.nao_cadastrados,
    }

    localStorage.setItem("sendAnexoApi", JSON.stringify(aux))
  } catch (e) {
    Swal.fire({ title: "Atenção!", text: e.message, icon: "error" })
    window.location.reload()
  }
}
