import React, { useEffect, useState } from "react"
import {
  Container,
  FirstCard,
  SecondCard,
  Form,
  Field,
  Heading,
  Mfield2,
  Mfield1,
  Spacediv,
  DropDiv,
  Heading2,
  Icon,
  InputFile,
  DropDiv2,
  InputFileManual,
  Button1,
  Button2,
  Buttons,
  ButtonsDiv,
  ImageContainer,
  RemoveIcon,
  Image,
  Imagexlsx,
  Arroba,
  HeaderDiv,
  Iconview,
  IconeDive,
  Paragrafo,
} from "./style"
import api from "../../../Services/api"
import xlsx from "../../../assets/images/Iconxlsx.png"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { SendAnexoApi } from "../../../Services/sendAnexo"
import { SendImageApi } from "../../../Services/sendImage."
import Icone from "../../../utils/Icone"
import ShowImagem from "../ImageExemple"
import Button from "../../Button"
import ResponseXlsx from "../StatusXslx"

export default function CadastroImport({ Show, setShow }) {
  const [Nome, setNome] = useState("")
  const [RM, setRM] = useState("")
  const [Email, setEmail] = useState("")
  const [Curso, setCurso] = useState("")
  const [DataNascimento, setDataNascimento] = useState("")
  const [DataInicio, setDataInicio] = useState("")
  const [DataTermino, setDataTermino] = useState("")
  const [Convenio, setConvenio] = useState("")
  const [NomeAnexo, setNomeAnexo] = useState("")

  const [FileImg, setFileImg] = useState("")
  const [showImagem, setShowImagem] = useState(false)
  const [NomeArquivo, setNomeArquivo] = useState("")
  const [Tipo, setTipo] = useState("")
  const [File, setFile] = useState("")

  const [FileManual, setFileManual] = useState("")
  const [NomeArquivoManual, setNomeArquivoManual] = useState("")
  const [TipoManual, setTipoManual] = useState("")
  const [FileImgManual, setFileImgManual] = useState("")

  const [optionsCurso, setOptionsCurso] = useState([])
  const [optionsConvenios, setoptionsConvenios] = useState([])

  const [activedEvent, setActivedEvent] = useState(false)
  const [resRm, setResRm] = useState([])
  const [resNome, setResNome] = useState([])

  const Navigate = useNavigate()

  const [Estado, setEstado] = useState(true)

  useEffect(() => {
    async function PegaCursos() {
      await api
        .get("/curso/listar?procurar")
        .then((res) => {
          const { data } = res
          const resultsCurso = []
          data.forEach((value) => {
            resultsCurso.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsCurso([{ label: " - ", value: null }, ...resultsCurso])
        })
        .catch((err) => {
          console.log("deu erro X--> ", err)
        })
    }
    PegaCursos()
  }, [])

  useEffect(() => {
    if (Curso == "") {
      console.log("Não ativou, curso vazio")
    } else {
      api
        .get(`/curso/listar-convenios?procurar=${Curso}`)
        .then((res) => {
          const data = res.data
          const resultsConvenios = [
            {
              label: " - ",
              value: "",
            },
          ]
          data.forEach((value) => {
            resultsConvenios.push({
              label: value.convenio,
              value: value.convenio,
            })
          })
          setoptionsConvenios(resultsConvenios)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [Curso])

  const aluno = {
    email: Email,
    rm: RM,
    nome: Nome,
    dt_nascimento: DataNascimento,
    dt_inicio: DataNascimento,
    dt_termino: DataNascimento,
    id_curso: Curso,
    convenio: Convenio,
  }

  function DisableShow() {
    return null
  }

  const vazio = [{ label: "Selecione o curso", value: "" }]

  function setAllFalse() {
    setNome("")
    setRM("")
    setEmail("")
    setDataNascimento("")
    setDataInicio("")
    setDataTermino("")
    setCurso("")
    setConvenio("")
    setFileManual("")
    setNomeArquivoManual("")
    setTipoManual("")
    setFileImgManual("")
  }

  async function HandleManualSubmit(e) {
    if (File) {
      const datas = {
        tituloDocumento: Tipo,
        NomeArquivo: NomeArquivo,
      }
      SendAnexoApi(datas, File)
      var aux = localStorage.getItem("sendAnexoApi")
      const data = JSON.parse(aux)
      Swal.fire({
        html: `
          <div style="display: flex; align-items: flex-start; justify-content: center; text-align: left; font-family: Arial, sans-serif; 
                flex-direction: column; align-content: center; flex-wrap: wrap; gap: 5px;">
              <div style="display:'flex';">Número de Alunos:  ${data?.num_alunos}</div>
              <div style="display:'flex';">Cadastros com Sucesso:  ${data?.sucessos_cadastro}</div>
              <div style="display:'flex';">Falha no Cadastro:  ${data?.nao_cadastrados}</div>
              <div style="display:'flex';">RM Duplicado:  ${data?.rm_duplicado}</div>
              <div style="display:'flex';">Falta de Informações:  ${data?.falta_info}</div>
              <div style="display:'flex';">RM Inválido:  ${data?.rm_invalido}</div>
              <div style="display:'flex';">Erro com Data de Nascimento:  ${data?.data_nasc}</div>
              <div style="display:'flex';">Erro com Data de Início:  ${data?.data_inic}</div>
              <div style="display:'flex';">Erro com Data de Término:  ${data?.data_term}</div>
              <div style="display:'flex';">Erro ao Buscar o Curso:  ${data?.buscar_curso}</div>
              <div style="display:'flex';">Erro no cadastro de Aluno:  ${data?.erro_cadastro}</div>
              <div style="display:'flex';">Erro no Servidor:  ${data?.erro_servidor}</div>
        </div>
        `,
        icon: "success",
        confirmButtonText: "Ver alunos não inseridos",
        confirmButtonColor: "	#0080ff",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#ff3333",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setResRm(data.rm_nao_cadastrados)
          setResNome(data.nome_nao_cadastrados)
          setActivedEvent(true)
        }else{
          window.location.reload()
        }
      })
      setNomeArquivo("")
      setTipo("")
      setFile("")
      setNomeAnexo("")
    } else {
      if (
        RM !== "" &&
        Email !== "" &&
        Nome !== "" &&
        DataNascimento !== "" &&
        DataInicio !== "" &&
        DataTermino !== "" &&
        Convenio !== "" &&
        Curso !== ""
      ) {
        api
          .post("/aluno/cadastrar", { aluno })
          .then(async (res) => {
            Swal.fire({
              title: "Concluido",
              text: `${res.data.mensagem}`,
              icon: "success",
              iconColor: "green",
              confirmButtonColor: "var(--azul)",
              confirmButtonText: "Confirmar",
            })
            const data = {
              tituloDocumento: TipoManual,
              NomeArquivo: RM,
            }
            SendImageApi(data, FileManual)
            setAllFalse()
          })
          .catch((err) => {
            console.log("erro: ", err)
            Swal.fire({
              title: "Erro",
              text: `${err.response.data.erro.split(".")[0]}`,
              icon: "error",
              iconColor: "red",
              confirmButtonColor: "var(--azul)",
              confirmButtonText: "Confirmar",
            })
          })
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Preencha todos os campos",
        })
      }
    }
  }

  if (Show == true) {
    return (
      <>
        <ResponseXlsx activedEvent={activedEvent} setActivedEvent={setActivedEvent} resRm={resRm} resNome={resNome} />
        <ShowImagem setShowImagem={setShowImagem} showImagem={showImagem} />
        <SecondCard Estado={Estado}>
          <Arroba
            onClick={() => {
              setAllFalse()
              setEstado(false)
              setTimeout(() => {
                setShow(false)
                setEstado(true)
              }, 600)
            }}
          >
            <Icone name={"X"} color={"#0d467e"} size={46} />
          </Arroba>
          <Spacediv>
            <HeaderDiv>
              <Heading2>Importar alunos (.XLSX)</Heading2>
              {/* <Paragrafo>
               XLSX (gerado pelo Sophia). A estrutura do arquivo .XLSX pode ser visualizada{" "}
                <p
                  onClick={() => {
                    setShowImagem(true)
                  }}
                  style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
                >
                  aqui.
                </p>
              </Paragrafo> */}
            </HeaderDiv>
            <DropDiv>
              <InputFile
                type="file"
                accept=".csv, .xlsx"
                onChange={(e) => {
                  if (e?.target?.files[0]) {
                    const allowedFormats = [".csv", ".xlsx"]
                    const fileExtension = e.target.files[0].name.split(".").pop()

                    if (allowedFormats.includes("." + fileExtension)) {
                      setFileImg(URL.createObjectURL(e.target.files[0]))
                      setFile(e.target.files[0])
                      setTipo(e.target.files[0].type)
                      setNomeArquivo(e.target.files[0].name)
                    } else {
                      Swal.fire({
                        title: "Atenção!",
                        text: "Formato não permitido!",
                        icon: "warning",
                      })
                    }
                  }
                }}
              />
              {File ? (
                <imageContainer>
                  <RemoveIcon
                    onClick={() => {
                      setFile("")
                      setFileImg("")
                      setTipo("")
                      setNomeArquivo("")
                    }}
                  />
                  <Imagexlsx>
                    <img
                      src={xlsx}
                      style={{
                        marginLeft: "25%",
                        marginTop: "15%",
                        maxWidth: "55%",
                      }}
                    />
                  </Imagexlsx>
                </imageContainer>
              ) : (
                <Icon />
              )}
            </DropDiv>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 30,
                position: "absolute",
                width: "20%",
                top: "90%",
                left: "78%",
                heigh: "50vh",
              }}
            >
              <Button
                width="55%"
                height="10%"
                placeholder="Enviar"
                event={(e) => {
                  HandleManualSubmit(e)
                }}
              />
            </div>
          </Spacediv>
        </SecondCard>
      </>
    )
  } else {
    return null
  }
}
