import React, { useState } from "react"
import Input from "../../Input"
import Button from "../../Button"
import { Buttons, Card, Contanier, Header, Table, Theader, Trbody, Td, Th, Title, Grup, Tbody, ContainerTable } from "./style"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import { format } from "date-fns"
import Pesquisar from "../../Pesquisar"

export default function StatusImagens({ activedEvent, setActivedEvent, res }) {
  if (activedEvent == true) {
    return (
      <Contanier>
        <Card>
          <Header>
            <Title>Ver Fotos</Title>
          </Header>
          <ContainerTable>
            <Table>
              <Theader>
                <tr>
                  <Th>Nome/Rm</Th>
                </tr>
              </Theader>
              <Tbody>
                {res?.map((d, i) => (
                  <Trbody key={i}>
                    <Td>{d}</Td>
                  </Trbody>
                ))}
              </Tbody>
            </Table>
          </ContainerTable>
          <Header>
            <Button
              placeholder='Fechar'
              event={() => {
                window.location.reload();
                setActivedEvent(false);
              }}
            />
          </Header>
        </Card>
      </Contanier>
    )
  } else if (activedEvent == false) {
    return null
  }
}
