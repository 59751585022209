import styled from "styled-components";


export const Page = styled.div`
  width: 100vw;
  height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center;  */
  // border:5px solid black;
`

export const DivMain = styled.div`
  width: 90%;
  padding-top: 4rem ;
  padding-bottom: 4rem ;
`

export const DivTitle = styled.div`
display: grid;
grid-template-columns: 90% 90%;
align-items: center;
height: 100px;
width: 100%;
margin: 0 auto;
margin-top: 4%;
// border: 0.5px solid red;
// justify-content: space-around;
`
export const DivButton = styled.div`
display: grid;
grid-template-columns: 13% 83%;
height: 2.2rem;
margin-left: 3%;
justify-content: flex-start;
`

export const AddButton = styled.button`
height: 100%;
width: 100%;
color: white;
background-color: darkgreen;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
font-weight: 800;
transition: all 0.1s;


&:hover{
    background-color: darkseagreen;
    color: white;
    /* box-shadow: 5px 5px gray; */
    transform: scale(1.005);
}
`

export const ExButton = styled.button`
height: 100%;
width: 100%;
color: white;
background-color: red;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
margin-left: 1%;
font-weight: 800;
transition: all 0.1s;


&:hover{
    background-color: white;
    color: black;
    /* box-shadow: 5px 5px gray; */
    transform: scale(1.005);
}
`

export const DivSave = styled.div`
display: flex;
align-items: flex-end;
justify-content: flex-end;
height: 100%;
width: 100%;
`

export const Save = styled.button`
height: 100%;
width: 15%;
color: white;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;
background-color: rgb(16, 130, 240);
font-weight: bold;
transition: all 0.2s;


&:hover{
    background-color: rgb(16, 172, 246);
    opacity:95%;
    font-weight: bold;
    box-shadow: 2px 2px darkgrey;
    transform: scale(1.01);
}
`
export const Title = styled.h1`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  margin-right: 1.5%;
  font-weight: 200;
  border-radius: 20px 20px 0px 0px;
  gap: 0.5em;
`
export const Description = styled.div`
  display: flex;
  padding: 10px 20px 0 20px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  flex-direction: column;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  margin-right: 1%;
  font-weight: 700;
  border-radius: 20px 20px 0px 0px;
  gap: 0.5em;
  
  p{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    gap: 1em;

    .terminou{
      border-radius: 100px;
      padding: 10px;
      background-color: #39b700;
    }
    .andamento{
      border-radius: 100px;
      padding: 10px;
      background-color: #dcb02a;
    }
  }
`
export const Menus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`

export const DivTable = styled.div`
display:flex;
flex-direction: column;
height: 100%;
width: 100%;
justify-content:center;
align-items:center;
overflow: hidden;
`

export const Buttons = styled.div`
  /* margin-left: 2vh; */
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  gap: 20px;
  /* justify-content: space-between;
  height: 23%; */
`
export const ContainerTable = styled.div`
  width: 99%;
  min-height: 60vh;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border: 1px solid #1a2e35; */
  border-top: 5px solid #1a2e35;
  border-bottom: 5px solid #1a2e35;
`;

export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Theader = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: 0;
  height: 8vh;
  font-size: 1rem;
  z-index: 9;
`;

export const Th = styled.th`
  padding: 2px 15px;
  text-align: center;
`;

export const Td = styled.td`
  padding: 4px 30px;
  text-align: start;

  p{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    text-transform: uppercase;
    white-space: break-spaces;
    gap: 0.5em;
    
    .terminou{
      border-radius: 100px;
      padding: 10px;
      background-color: #39b700;
    }
    .andamento{
      border-radius: 100px;
      padding: 10px;
      background-color: #dcb02a;
    }
  }

  ${({ $andamento }) => $andamento ? ` 
    color: #dcb02a;
    font-weight: 600;
  ` : ""}

  ${({ $terminou }) => $terminou ? ` 
    color: #39b700;
    font-weight: 600;
  ` : ""}
`;

export const TdDisciplina = styled.td`
  padding: 4px 30px;
  text-align: center;
  
  ${({ $andamento }) => $andamento ? ` 
    color: #dcb02a;
    font-weight: 600;
  ` : ""}

  ${({ $terminou }) => $terminou ? ` 
    color: #39b700;
    font-weight: 600;
  ` : ""}
`;

export const Tbody = styled.tbody`
  height: fit-content;
`;

export const Trbody = styled.tr`
  border-bottom: 1px solid #dddddd;
  height: 50px;

  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  transition: all 0.2s;

  &:hover {
    background-color: #bdbdbd;
    /* color: white; */
    transform: scale(1.01);
  }

  .icon{
  text-align: center;
  }
`;

export const DivText = styled.h2`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items:center;
  text-transform: uppercase;
  font-weight: 700;
`