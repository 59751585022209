import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
`

export const LeftDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

export const RightDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;   
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
`

export const Card = styled.div`
    display:flex;
    flex-direction: column;
    background-color: #ffa500;
    width: 70%;
    height: 100%;
    border-radius: 35px;
    align-items:center;
    gap: 10%;
`

export const Titulo = styled.p`
    font-size: 40px;
    width:fit-content;
    height:fit-content;
`

export const TitleHolder = styled.div`
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
    height: 10%;
    border-radius: 30px;
    border: 4px dashed black;
    background-color: var(--background);
`