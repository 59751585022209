import styled from "styled-components"

export const Contanier = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
`

export const Cards = styled.div`
  display: flex;
  width: 80%;
  height: 70%;
  flex-direction: column;
  z-index: 98;
  gap: 2rem;
  border-radius: 30px;
  background-color: var(--background);
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
`

export const Close = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  padding: 20px 10px 0px 30px;
  transition: transform 0.2s;
  // border: 1px solid black;

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
`
