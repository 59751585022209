import styled from "styled-components"

export const Container = styled.div`
  backdrop-filter: blur(0.5rem);
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -5%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff60;

  @media (max-height: 600px) {
    width: 90%;
    height: 150vh;
    margin-top: 0%;
  }
`

export const Lista = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`

export const Buttons = styled.div`
  width: 100%;
  margin-top: 5%;
  height: 4rem;
  display: flex;
  justify-content: right;
  .x{
    padding-right: 30px;
  }
  
  @media (max-height: 600px) {
    margin-top: 0%;
    .x{
      padding-right: 20px;
    }
  }
`

export const Item = styled.button`
  width: 170%;
  border-radius: 1rem;
  height: 3rem;
  background-color: black;
  color: white;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: 0.5s ease-in;

  &:hover {
    transform: translateY(-20deg);
    background-color: purple;
  }

  @media (max-height: 600px) {
    height: 2rem; 
  }
`
