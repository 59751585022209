import styled from "styled-components"
import { FaEdit, FaExternalLinkAlt, FaTrashAlt } from "react-icons/fa"
import { FaUserLargeSlash, FaUserCheck, FaRegImage } from "react-icons/fa6"
import { AiOutlineArrowLeft } from "react-icons/ai"

export const Container = styled.div`
  width: 90%;
  margin-left: 5%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // border:5px solid black;
  overflow-x: hidden;
  overflow-y: hidden;
`
export const ModalTest = styled.div`
  display: grid;
  grid-template-columns: repeat(10,1fr);
  position: absolute;
  margin-top: 50rem;
  /* left: 5; */
  text-align: center;
  align-items: center;
  width: 90vw;
  height: fit-content;
  /* margin-bottom: 50px; */
  z-index: 20;
  border: 3px solid black;
  background-color: rgba(255,255,255,0.1);
`

export const Header = styled.div`
  display: flex;
  justify-content: start;
  margin: 0 auto;
  width: 90%;
  font-size: 45px;
  text-align: center;
  padding: 10px;
`

export const IconeExit = styled(AiOutlineArrowLeft)`
  color: #0d467e;
  margin-top: 1%;
  cursor: pointer;
`

export const ContainerTable = styled.div`
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 5px solid #1a2e35;
  border-bottom: 5px solid #1a2e35;
  // border:5px solid blue;
  // margin-top:20px;
`
export const Title = styled.h1`
  padding: 10px 20px;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  margin-top: 1.5%;
  font-weight: 200;
  font-size: #1a2e35;
  border-radius: 20px 20px 0px 0px;
`
export const Menus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`

export const EditButton = styled(FaEdit)`
  cursor: pointer;
  margin-right: 1.4rem;
  margin-left: 1.4rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

export const DeleteButton = styled(FaTrashAlt)`
  cursor: pointer;
  margin-right: 1.4rem;
  margin-left: 1.4rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`
export const DesativarButton = styled(FaUserLargeSlash)`
  cursor: pointer;
  margin-right: 1.4rem;
  margin-left: 1.4rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

export const PreviewImage = styled.img`
  @media (max-height: 900px) {
    width: 35%;
    height: 35%;
  }

  cursor: pointer;
  margin-left: 25%;
  width: 15%;
  height: 5%;
  border: 1px solid black;
`

export const UploadPhto = styled(FaRegImage)`
  width: 100%;
  cursor: pointer;
`
export const AtivarButton = styled(FaUserCheck)`
  cursor: pointer;
  margin-right: 1.4rem;
  margin-left: 1.4rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
`
export const Theader = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: 0;
  height: 8vh;
  font-size: 1rem;
  z-index: 9;
`
export const Tbody = styled.tbody`
  height: fit-content;
`

export const Th = styled.th`
  padding: 2px 15px;
  text-align: center;
`
export const Td = styled.td`
  padding: 2px 25px;
  text-align: start;
`
export const TdUpload = styled.td`
  display: flex;
  align-items: center;
  padding: 20px 20px;
  text-align: start;
`

export const Trbody = styled.tr`
  border-bottom: 1px solid #dddddd;
  height: 50px;
  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  transition: all 0.2s;

  &:hover {
    background-color: grey;
    color: white;
    transform: scale(1.01);
  }
`
export const Colletion = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 40%;
  height: 90%;
`
export const Buttons = styled.div`
  margin-left: 2vh;
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  gap: 20px;
  // justify-content: space-between;
  // height: 23%;
`
export const Grup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  width: fit-content;
  ::placeholder {
    color: #ffffff;
  }
`
