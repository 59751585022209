import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const GerarPDF = (Alunos, content) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const tituloRelatorio = [
    {
      text: content.titulo,
      width: 500,
      color: 'red',
      fontSize: 15,
      bold: true,
      alignment: 'center',
      margin: [15, 20, 0, 45], // left, top, right, bottom
    }
  ];

  const dados = Alunos.map((aluno) => {
    const cumpriu = aluno.cumpriu === 1 ? 'Sim' : aluno.cumpriu === 0 ? 'Não' : 'Não Avaliado';
    const conceito = aluno.conceito || 'Não Avaliado';
    // const corApto = aluno.conceito == 'apto' ? '#39b700' : '#ff3535'

    return [
      { text: aluno.rm, fontSize: 10, margin: [0, 2, 2, 0], color: "black", alignment: 'center', },
      { text: aluno.nome_aluno, fontSize: 10, margin: [0, 2, 2, 0], color: "black", alignment: 'left', },
      { text: aluno.nome_curso, fontSize: 10, margin: [0, 2, 2, 0], color: "black", alignment: 'center', },
      { text: aluno.convenio, fontSize: 10, margin: [0, 2, 2, 0], color: "black", alignment: 'center', },
      { text: cumpriu, fontSize: 10, margin: [0, 2, 2, 0], color: "black", alignment: 'center', },
      { text: conceito, fontSize: 10, margin: [0, 2, 2, 0], color: "black", alignment: 'center', },
    ]
  });

  const estilosHeader = {
    tableHeader: {
      fontSize: 12,
      bold: true,
      fillColor: 'gray', // Cor do cabeçalho
      color: '#FFFFFF',     // Cor do texto do cabeçalho
      alignment: 'center'
    }
  };

  const Conteudo = [
    { text: `Turma: ${content.turma}`, alignment: 'start', margin: [0, 5, 0, 0] },
    { text: `Disciplina: ${content.disciplina}`, alignment: 'start', margin: [0, 5, 0, 0] },
    { text: `Professor(a): ${content.professor}`, alignment: 'start', margin: [0, 5, 5, 0] },
    // {
    //   ul: [
    //     `Curso: ${content.curso}`,
    //     `Turma: ${content.turma}`,
    //     `Disciplina: ${content.disciplina}`,
    //   ],
    //   fontSize: 12,
    //   bold: true,
    //   margin: [0, 5, 0, 5],
    // },
    {
      columns: [
          { text: `Início: ${content.DataInicio}`, alignment: 'start' },
          { text: `Encerrado: ${content.DataFim}`, alignment: 'right' }
      ],
      fontSize: 12,
      margin: [10, 20, 10, 5], // left, top, right, bottom
    },
    {
      style: 'tableExample',
      table: {
        headerRows: 1,
        widths: [40, 200, '*', 55, 50, 50],
        body: [
          [
            { text: 'RM', style: 'tableHeader' },
            { text: 'Nome', style: 'tableHeader' },
            { text: 'Curso', style: 'tableHeader' },
            { text: 'Convênio', style: 'tableHeader' },
            { text: 'Cumpriu', style: 'tableHeader' },
            { text: 'Conceito', style: 'tableHeader' },
          ],
          ...dados // Aqui você inclui as linhas de dados
        ]
      },
      layout: {
        fillColor: function (rowIndex) {
          return (rowIndex % 2 === 0) ? '#dddddd' : '#f3f3f3';
        }
      }
    },
  ];


  function rodape(currentPage, pageCount) {
    return [
      {
        columns: [
          { text: currentPage + ' / ' + pageCount, alignment: 'center' }
        ],
        fontSize: 9,
        margin: [20, 0, 20, 0], // left, top, right, bottom
        columnGap: 10,  // Espaçamento entre as colunas
      },
    ]
  }
  const definicaoDocumento = {
    pageSize: 'A4',
    pageMargins: [15, 50, 15, 40],
    header: tituloRelatorio,
    content: [Conteudo],
    footer: rodape,
    styles: estilosHeader
  };


  pdfMake.createPdf(definicaoDocumento).download();
};

export default GerarPDF;


/* 
  Como Funciona o PdfMaker:

  */