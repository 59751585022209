import styled from "styled-components"

export const Contanier = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 90;
`
export const Card = styled.div`
  width: 22rem;
  height: 63vh;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
`
export const Title = styled.h1`
  margin-top: 6%;
  font-weight: 300;
`
export const Header = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  width: 100%;
  height: 10%;
`

export const Forms = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 70%;
  justify-content: left;
`

export const Buttons = styled.button`
  width: 10%;
  height: 70%;
  margin-right: 5%;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
`

export const ButtonNew = styled.div`
  width: 100%;
  height: 10%;
  margin-top: 10%;
  display: flex;
`
export const ButtonSeach = styled.button`
  background: #3a3f86;
  border: none;
  align-items: center;
  justify-content: center;
  margin-top: 6%;
  color: white;
  width: 20%;
  height: 50%;
  border-radius: 10%;
`

export const paragrafo = styled.p`
  font-size: 1rem;
`

export const Gruop = styled.div`
  width: 85%;
  height: 70%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 15%;
`

export const Image = styled.img`
  margin-top: 15%;
  max-width: 20rem;
`
