import React, { useState } from "react"
import {
  CardOne,
  CardTree,
  CardTwo,
  Cards,
  Cardsdiv,
  Check,
  Container,
  Header,
  HeaderText,
  IconArea,
  IconOne,
  IconTree,
  IconTwo,
  IconeExit,
  Mini,
  MiniTitle,
  Text,
  TextArea,
} from "./style"
import "./style.css"
import Input from "../../Components/Input"
import CadastroManual from "../../Components/modals/CadastroManual"
import CadastroImport from "../../Components/modals/CadastroImport"
import { useNavigate } from "react-router-dom"
import CadastroPhoto from "../../Components/modals/CadastroPhotos"

export default function CadastroAluno() {
  const Navigate = useNavigate()
  const [Show, setShow] = useState(false)
  const [ShowImport, setShowImport] = useState(false)
  const [ShowPhoto, setShowPhoto] = useState(false)

  return (
    <Container>
      <CadastroManual Show={Show} setShow={setShow} />
      <CadastroImport Show={ShowImport} setShow={setShowImport} />
      <CadastroPhoto setShowPhoto={setShowPhoto} ShowPhoto={ShowPhoto} />
      <br />
      <Header>
        <IconeExit
          onClick={() => {
            Navigate("/visualizaralunos")
          }}
        />
      </Header>
      <Cards>
        <Cardsdiv>
          <CardOne onClick={() => setShow(true)}>
            <Check>
              <input className="CheckOne" type={"checkbox"} />
            </Check>
            <IconArea>
              <IconOne />
            </IconArea>
            <MiniTitle>
              <Mini>Cadastro Manual</Mini>
            </MiniTitle>
            <TextArea>
              <Text>Inclusão de um aluno por vez preenchendo suas credenciais para o cadastro correto.</Text>
            </TextArea>
          </CardOne>
          <CardTwo onClick={() => setShowImport(true)}>
            <Check>
              <input className="CheckTwo" type={"checkbox"} />
            </Check>
            <IconArea>
              <IconTwo />
            </IconArea>
            <MiniTitle>
              <Mini>Importação de Alunos</Mini>
            </MiniTitle>
            <TextArea>
              <Text>Inclusão de um lista de alunos a partir de um arquivo XLSX. </Text>
            </TextArea>
          </CardTwo>
          <CardTree onClick={() => setShowPhoto(true)}>
            <Check>
              <input className="CheckTree" type={"checkbox"} />
            </Check>
            <IconArea>
              <IconTree />
            </IconArea>
            <MiniTitle>
              <Mini>Importação de Fotos</Mini>
            </MiniTitle>
            <TextArea>
              <Text>Carregar as fotos dos alunos.</Text>
            </TextArea>
          </CardTree>
        </Cardsdiv>
      </Cards>
    </Container>
  )
}
