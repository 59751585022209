import { Buttons, Container, Item, Lista } from "./style"
import RecuperarSenha from "../../modals/RecuperarSenha"

import { X } from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import QrCodeDadosPublicos from "../../modals/QrCodeDadosPublicos/QrCodeDadosPublicos"

export function MenuAluno({ open, setOpen, componentVerticalRef, componentRef, isRotated, senMenuDonwload }) {
  const Navigate = useNavigate()
  const [isActived, setIsActived] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (open == true) {
    return (
      <>
        <RecuperarSenha isActived={isActived} setIsActived={setIsActived} />
        <QrCodeDadosPublicos isShow={isShow} setIsShow={setIsShow} />
        <Container>
          <Buttons>
            <X
              style={{ marginRight: 30, color: "black" }}
              size={40}
              onClick={() => {
                setOpen(false)
              }}
            />
          </Buttons>
          <Lista>
            <Item
              onClick={() =>
                senMenuDonwload(
                  (windowSize.height > 600 ? componentVerticalRef : componentRef),
                  (windowSize.height > 600 ? 'landscape' : 'portrait')
                )}
            >
              Baixar Carterinha
            </Item>
            <Item
              onClick={() => Navigate('/progressao-parcial')}
            >
              Progressão Parcial
            </Item>
            <Item
              onClick={() => {
                setIsActived(true)
              }}>
              Mudar Senha
            </Item>
            <Item
              onClick={() => {
                setIsShow(true)
              }}>
              QR CODE
            </Item>
            <Item
              onClick={() => {
                Navigate("/")
              }}>
              Sair
            </Item>
          </Lista>
        </Container>
      </>
    )
  } else {
    return null
  }
}
