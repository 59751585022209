import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
`

export const Img = styled.img`
    position: relative;
    margin: 0 auto;
    width: 80%;
    height: 80vh;
`