import React, { useEffect, useState } from "react";
import "./style.css";
import { Container, ContainerTable, Menus, Table, Theader, Trbody, Td, Th, Title, Buttons, Grup, Tbody, VerMais, TdNome, VerMaisIcon } from "./style";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Pesquisar from "../../Components/Pesquisar";
import api from "../../Services/api";
import { Alert } from "../../utils/Alert";
import InputSelect from "../../Components/Select";
import ViewAluno from "../../Components/modals/ViewAluno";

export default function VerCarterinhas() {
  const Navigate = useNavigate();
  const [Show, setShow] = useState(false);
  const [initalLogs, setInitalLogs] = useState([]);
  const [logs, setLogs] = useState([]);
  const [searchNomeUsuario, setSearchNomeUsuario] = useState("");
  const [searchNomeAluno, setSearchNomeAluno] = useState("");
  const [searchAcao, setSearchAcao] = useState("");
  const [searchDataHora, setSearchDataHora] = useState("");
  const [searchMensagem, setSearchMensagem] = useState("");
  const [mostrarTodos, setMostrarTodos] = useState(250);
  const [optionsAcao, setOptionsAcao] = useState([]);
  const [id_Aluno, setId_Aluno] = useState('')
  const [id_Usuario, setId_Usuario] = useState('')
  const [rm, setRm] = useState('')
  const limite = 0.20; // mostrará apenas 20%

  useEffect(() => {
    async function getallLogs() {
      try {
        const response = await api.get("/log/listar");
        const data = response.data;
        setInitalLogs(data);
        setLogs(data);
        setMostrarTodos(data.length * limite);
        // console.log(data);
        // Use new Set para não repetir as ações, deixando apenas uma de cada
        const actionSet = new Set();
        data.forEach((value) => {
          if (value.acao) { // Verifica se value.acao não é null ou undefined
            const acao = value.acao.split(' do ip')[0].split(" ip")[0].split('undefined')[0]; // Aplica o split aqui
            actionSet.add(acao);
          }
        });
        const resultsAcao = Array.from(actionSet).map((acao) => ({
          label: acao,
          value: acao,
        }));
        setOptionsAcao([{ label: "Escolha uma ação", value: null }, ...resultsAcao]);
      } catch (err) {
        console.error(err);
      }
    }
    getallLogs();
  }, []);

  async function DeletarLogs() {
    try {
      const response = await api.delete("/log/deletar-log");
      Alert('Sucesso2', response.data.mensagem);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (
      searchNomeUsuario.length > 0 ||
      searchNomeAluno.length > 0 ||
      searchAcao.length > 0 ||
      searchDataHora.length > 0 ||
      searchMensagem.length > 0
    ) {
      setMostrarTodos(logs.length);
    } else {
      setMostrarTodos(logs.length * limite);
    }
  }, [searchNomeUsuario, searchNomeAluno, searchAcao, searchDataHora, searchMensagem]);

  const normalizeString = (str) => {
    if (str === undefined || str === null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredLogs = logs.filter((Logs) => {
    const searchNomeUsuarioNormalized = normalizeString(searchNomeUsuario);
    const searchNomeAlunoNormalized = normalizeString(searchNomeAluno);
    const searchAcaoNormalized = normalizeString(searchAcao);
    const searchDataHoraNormalized = normalizeString(searchDataHora);
    const searchMensagemNormalized = normalizeString(searchMensagem);

    const LogsNomeUsuario = normalizeString(Logs.nome_secretario || "");
    const LogsNomeAluno = normalizeString(Logs.nome_aluno || "");
    const LogsAcao = normalizeString(Logs.acao || "");
    const LogsDataHora = normalizeString(Logs.data_hora ? format(new Date(Logs.data_hora), "dd/MM/yyyy") : "");
    const LogsMensagem = normalizeString(Logs.mensagem || "");

    return (
      LogsNomeUsuario.includes(searchNomeUsuarioNormalized) &&
      LogsNomeAluno.includes(searchNomeAlunoNormalized) &&
      LogsAcao.includes(searchAcaoNormalized) &&
      LogsDataHora.includes(searchDataHoraNormalized) &&
      LogsMensagem.includes(searchMensagemNormalized)
    );
  }).slice(0, mostrarTodos);

  return (
    <Container>
      <Menus>
        <Title>Controle de Acessos</Title>
        <Buttons>
          <Button
            event={() => {
              Navigate("/secretaria");
            }}
            width="12%"
            margintop="2%"
            placeholder="Voltar"
            backgroundcolor="#1a2e35"
            borderRadius="30px 30px 0px 0px"
          />
          {mostrarTodos < logs.length ? (
            <Button
              event={() => setMostrarTodos(logs.length)}
              width="12%"
              margintop="2%"
              backgroundcolor="#1a2e35"
              placeholder="Mostrar Todos"
              borderRadius="30px 30px 0px 0px"
            />
          ) : (
            <Button
              event={() => setMostrarTodos(logs.length * limite)}
              width="12%"
              margintop="2%"
              backgroundcolor="#1a2e35"
              placeholder="Reduzir Logs"
              borderRadius="30px 30px 0px 0px"
            />
          )}
        </Buttons>
        <Title>{filteredLogs.length}</Title>
      </Menus>
      <ContainerTable>
        <Table>
          <Theader>
            <tr>
              <Th>
                <Grup>
                  <Pesquisar
                    color=""
                    placeholder="Nome do Usuário"
                    name="nome_usuario"
                    event={(e) => {
                      setSearchNomeUsuario(e.target.value);
                    }}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    placeholder="Nome do Aluno"
                    name="nome_aluno"
                    event={(e) => {
                      setSearchNomeAluno(e.target.value);
                    }}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <InputSelect
                    Label={"Açao"}
                    Options={optionsAcao}
                    Default={"-"}
                    OnChange={(e) => { setSearchAcao(e.target.value) }}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    placeholder="Data e Hora"
                    name="data"
                    event={(e) => {
                      setSearchDataHora(e.target.value);
                    }}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    placeholder="Mensagem"
                    name="mensagem"
                    event={(e) => {
                      setSearchMensagem(e.target.value);
                    }}
                  />
                </Grup>
              </Th>
              <Th></Th>
            </tr>
          </Theader>
          <Tbody>
            {filteredLogs?.map((d) => (
              <Trbody key={d?.id}>
                <Td>{d?.nome_secretario === "0" ? " " : <TdNome>{d?.nome_secretario}</TdNome>}</Td>
                <Td>{d?.nome_aluno === "0" ? "" : <TdNome>{d?.nome_aluno}</TdNome>}</Td>
                <Td>{d?.acao}</Td>
                <Td>{format(new Date(d?.data_hora), "dd/MM/yyyy HH:mm:ss")}</Td>
                <Td>{d?.mensagem}</Td>
                <Td>
                  {d.mensagem?.includes('RM:') && !d.mensagem?.includes('RM:undefined') ? (
                    <TdNome>
                      <VerMais
                        onClick={() => {
                          const rm = d?.mensagem.split('RM:')[1];
                          setShow(true);
                          setRm(rm.trim());
                        }}
                      >
                        <VerMaisIcon title="Ver Informações do Aluno" />
                      </VerMais>
                    </TdNome>
                  ) : d?.nome_secretario !== "0" && !d.mensagem?.includes('RM:') ? (
                    <TdNome>
                      <VerMais
                        onClick={() => {
                          setShow(true);
                          setId_Usuario(d?.id_usuario);
                        }}
                      >
                        <VerMaisIcon title="Ver Informações do Usuario" />
                      </VerMais>
                    </TdNome>
                  ) : d?.nome_aluno !== "0" && (
                    <TdNome>
                      <VerMais
                        onClick={() => {
                          setShow(true);
                          setId_Aluno(d?.id_aluno);
                        }}
                      >
                        <VerMaisIcon title="Ver Informações do Aluno" />
                      </VerMais>
                    </TdNome>
                  )}
                </Td>
              </Trbody>
            ))}
          </Tbody>
        </Table>
      </ContainerTable>
      <ViewAluno
        Show={Show}
        setShow={setShow}
        id_Usuario={id_Usuario}
        setId_Usuario={setId_Usuario}
        id_Aluno={id_Aluno}
        setId_Aluno={setId_Aluno}
        rm={rm}
        setRm={setRm}
      />
    </Container >
  );
}
