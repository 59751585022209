import React, { useEffect, useState } from "react"
import {
  SecondCard,
  Spacediv,
  DropDiv,
  Heading2,
  Icon,
  InputFile,
  ImageContainer,
  RemoveIcon,
  Imagecsv,
  Arroba,
  HeaderDiv,
  Paragrafo,
} from "./style"
import upload from "../../../assets/images/upload.webp"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import Icone from "../../../utils/Icone"
import Button from "../../Button"
import api from "../../../Services/api"
import Spinner from "../../Spiner"
import StatusImagens from "../StatusImagens"

export default function CadastroPhoto({ ShowPhoto, setShowPhoto }) {
  if (ShowPhoto == true) {
    const Navigate = useNavigate()
    const [Estado, setEstado] = useState(true)
    const [actived, setActived] = useState(false)
    const [activeEvent, setActivedEvent] = useState(false)
    const [res, setRes] = useState([])
    const [images, setImages] = useState([])

    // useEffect(() => {
    //   console.log(images)
    // }, [images])

    const handleImageChange = (e) => {
      const selectedImages = Array.from(e.target.files)
      setImages(selectedImages)
    }
    // console.log(images);

    async function HandleManualSubmit(e) {
      const formData = new FormData()

      images.forEach((image, index) => {
        formData.append(`file`, image)
      })

      try {
        setActived(true)
        const response = await api.post("aluno/cadastrar-imagens", formData, {})
        if (response.status == 200) {
          setActived(false)
          setImages([])
        }
        const data = response.data
        Swal.fire({
          text: ` ${data.mensagem.sucessos_cadastro}, ${data.mensagem.erro_cadastro}`,
          icon: "success",
          confirmButtonText: "Ver Imagens não inseridas",
          confirmButtonColor: "	#0080ff",
          cancelButtonText: "Cancelar",
          cancelButtonColor: "#ff3333",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            setRes(data.resultado.erro)
            setActivedEvent(true)
          } else {
            window.location.reload()
          }
        })
      } catch (error) {
        console.error("Error uploading images:", error)
      }
    }
    return (
      <>
        <StatusImagens setActivedEvent={setActivedEvent} activedEvent={activeEvent} res={res} />
        <SecondCard Estado={Estado}>
          <Arroba
            onClick={() => {
              setEstado(false)
              setTimeout(() => {
                setEstado(true)
                setShowPhoto(false)
              }, 600)
            }}
          >
            <Icone name={"X"} color={"#0d467e"} size={46} />
          </Arroba>
          <Spacediv>
            <HeaderDiv>
              <Heading2>Importar Fotos </Heading2>
              <Paragrafo>Arraste as fotos abaixo para enviá las</Paragrafo>
            </HeaderDiv>
            <DropDiv>
              <InputFile
                type="file"
                accept=".jpg"
                multiple="true"
                onChange={(e) => {
                  if (!e.target?.files[0]) {
                    Swal.fire({
                      title: "Atenção!",
                      text: "Nenhuma imagem selecionada !",
                      icon: "warning",
                    })
                  }
                  if (e?.target?.files[0]?.type.includes("jpeg")) {
                    handleImageChange(e)
                  } else {
                    Swal.fire({
                      title: "Atenção!",
                      text: "Formato não permitido!",
                      icon: "warning",
                    })
                  }
                }}
              />
              {images.length == 0 ? (
                <Icon />
              ) : (
                <ImageContainer>
                  {actived == true ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    <Imagecsv>
                      <img
                        src={upload}
                        style={{ height: '10rem', width: '10rem' }}
                      />
                    </Imagecsv>
                  )}
                  <RemoveIcon
                    title="remover foto(s)"
                    onClick={() => {
                      setImages([])
                      setActived(false)
                    }}
                  />
                </ImageContainer>
              )}
            </DropDiv>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 30,
                position: "absolute",
                width: "20%",
                top: "90%",
                left: "78%",
                heigh: "50vh",
              }}
            >
              <Button
                width="55%"
                height="10%"
                placeholder="Enviar"
                event={(e) => {
                  HandleManualSubmit(e)
                }}
              />
            </div>
          </Spacediv>
        </SecondCard>
      </>
    )
  } else {
    return null
  }
}
