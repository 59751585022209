import styled from "styled-components"

export const Contanier = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
`
export const Card = styled.div`
  width: fit-content;
  min-width: 40vw;
  height: 76vh;
  max-height: 90%;
  z-index: 99;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
`
export const Title = styled.h1`
  margin-top: 6%;
  font-weight: 300;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10%;
  width: 100%;
  height: 10%;
  margin-bottom:20px;
`

export const Forms = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 70%;
  justify-content: left;
`

export const Buttons = styled.button`
  width: 10%;
  height: 70%;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
`

export const ButtonNew = styled.div`
  width: 100%;
  height: 10%;
  margin-top: 10%;
  display: flex;
`
export const ButtonSeach = styled.button`
  background: #3a3f86;
  border: none;
  align-items: center;
  justify-content: center;
  margin-top: 6%;
  color: white;
  width: 20%;
  height: 50%;
  border-radius: 10%;
`

export const paragrafo = styled.p`
  font-size: 1rem;
`

export const Gruop = styled.div`
  width: 10%;
  height: 10%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 15%;
`

export const ContainerTable = styled.div`
  width: 80%;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 5px solid #1a2e35;
  border-bottom: 5px solid #1a2e35;
  // border:5px solid blue;
  margin-bottom:20px;
  // Hide scrollbar for IE, Edge and Firefox 
  /* -ms-overflow-style: none;   //IE and Edge 
  scrollbar-width: none;  // Firefox 
  // Hide scrollbar for Chrome, Safari, and Opera 
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  } */
`

export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
  overflow: scroll;
`
export const Theader = styled.thead`
  height: 4rem;
  position: sticky;
  top: 0;
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  z-index: 101;
  /* opacity: 0; */
`
export const Tbody = styled.tbody`
  height: fit-content;
`

export const Th = styled.th`
  padding: 2px 15px;
  text-align: center;
`
export const Td = styled.td`
  padding: 2px 25px;
  text-align: center;
`
export const TdUpload = styled.td`
  display: flex;
  align-items: center;
  padding: 20px 20px;
  text-align: start;
`

export const Trbody = styled.tr`
  border-bottom: 1px solid #dddddd;
  height: 50px;
  max-height: 20%;
  overflow: scroll;
  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  transition: all 0.2s;

  &:hover {
    background-color: grey;
    color: white;
    transform: scale(1.01);
  }
`
export const Colletion = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 40%;
  height: 90%;
`

export const Grup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  width: fit-content;
  ::placeholder {
    color: #ffffff;
  }
`

// Isso é a real progamação !!!
