import styled from "styled-components"
// Buttons, Container, ContainerMenu, Menu, MainDiv
export const Container = styled.div`
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
`
export const ContainerMenu = styled.div`
  width: 20vw;
  height: 10vh;
  display: flex;
  align-items: center;
`

export const Buttons = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vw;
`

export const Menu = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: right;
  padding-right: 20%;
  z-index: 1;
`

export const MainDiv = styled.div`
  display: flex;
  width: 100vw;
  /* margin:auto 0 ; */
  justify-content: space-around;
  align-items: center;
`