import React from "react"
import Logo from "../../../assets/images/logocarterinha.png"
import User from "../../../assets/images/user.png"
import "./style.css"

export default function CrachaMobile() {
  return (
    <div className="cardM">
      <div className="border-top"></div>
      <div className="info">
        <div className="fotoss">
          <img src={User} alt="perfil" className="user" />
        </div>
        <span>Secretaria</span>
        <img src={Logo} alt="" srcset="" className="logoss" />
        <div className="targetM">
          <p>Funcionário</p>
        </div>
      </div>
    </div>
  )
}
