import { X } from "lucide-react"
import { Buttons, Container, Item, Lista } from "./style"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import ResetarSenha from "../../modals/ResetarSenha"
import CriaCarterinha from "../../modals/GerarCracha"

export function MenuSecretaria({ open, setOpen }) {
  if (open == false) { return null }
  const [resetEvent, setResetEvent] = useState(false)
  const [gerarCracha, setGerarCracha] = useState(false)
  const Navigate = useNavigate()

  return (
    <>
      <ResetarSenha resetEvent={resetEvent} setResetEvent={setResetEvent} />
      <CriaCarterinha gerarCracha={gerarCracha} setGerarCracha={setGerarCracha} />
      <Container>
        <Buttons>
          <X
            className="x"
            style={{ color: "black" }}
            size={40}
            onClick={() => {
              setOpen(false)
            }}
          />
        </Buttons>
        <Lista>
          <Item
            onClick={() => {
              Navigate("/visualizaralunos")
            }}
          >
            Alunos
          </Item>
          <Item
            onClick={() => {
              Navigate("/visualizarusuarios")
            }}
          >
            Usuários
          </Item>
          <Item
            onClick={() => {
              setGerarCracha(true)
            }}
          >
            Gerar Crachá
          </Item>
          <Item
            onClick={() => {
              Navigate("/vercarterinhas")
            }}
          >
            Controle de Acessos
          </Item>
          <Item
            onClick={() => {
              setResetEvent(true)
            }}
          >
            Resetar Senha
          </Item>
          {/* <Item
              onClick={() => {
                Navigate("/desativaraluno")
              }}
            >
              Desativar Aluno
            </Item> */}
          <Item
            onClick={() => {
              Navigate("/")
            }}
          >
            Sair
          </Item>
        </Lista>
      </Container>
    </>
  )
}

