import React, { useEffect, useRef, useState } from "react"
import "./style.css"
import Input from "../../Input"
import Button from "../../Button"
import { AnimatedField, Buttons, Card, Contanier, DropDiv2, Forms, FotoIcon, Header, Image, ImageContainer, InputFileManual, RemoveIcon, Title } from "./style"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import { format, isAfter } from "date-fns"
import InputSelect from "../../Select"
import Foto from "../../../assets/images/user.png"
import Swal from "sweetalert2"
import { motion } from "framer-motion";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CriarCracha from "../../Pdf/CriarCracha"
import { Alert } from "../../../utils/Alert"

export default function GerarCracha({ gerarCracha, setGerarCracha }) {
  if (gerarCracha === false) { return null }
  const [isDownloading, setIsDownloading] = useState(false);
  const [formValues, setFormValues] = useState({
    nome: "", rm: "", matricula: "", perfil: "", outroPerfil: "",
    curso: "", convenio: "", cor_convenio: '', validade: "",
    fileManual: "", tipoManual: "", fileImgManual: Foto
  });
  const [optionsPerfil] = useState([
    { value: 0, label: "-" },
    { value: "Aluno", label: "Aluno" },
    { value: "Coordenador", label: "Coordenador" },
    { value: "Estagiário", label: "Estagiário" },
    { value: "Funcionário", label: "Funcionário" },
    { value: "Professor", label: "Professor" },
    { value: "Secretaria", label: "Secretaria" },
    { value: "Outro", label: "Outro" },
  ]);

  const [optionsCurso, setOptionsCurso] = useState([]);
  const [optionsConvenio, setOptionsConvenio] = useState([]);
  let minimo = new Date();
  minimo = minimo.setDate(minimo.getDate() + 1)
  const dataAtual = minimo;
  const componentVerticalRef = useRef(null);
  const [showRM, setShowRM] = useState(false);
  const [showMatricula, setShowMatricula] = useState(false);
  const [showCourseAndConvenio, setShowCourseAndConvenio] = useState(false);
  const [showOutro, setShowOutro] = useState(false);

  useEffect(() => {
    if (gerarCracha === false) { return null }
    const dataFormatada = format(dataAtual, 'yyyy-MM-dd');
    setFormValues((prevValues) => ({
      ...prevValues,
      validade: dataFormatada
    }));

    api.get("/curso/listar?procurar")
      .then((res) => {
        const data = res.data
        const resultsCurso = data.map((value) => ({
          label: value.nome,
          value: value.nome,
        }));
        setOptionsCurso([{ label: '-', value: 0 }, ...resultsCurso]);
      })
      .catch((err) => {
        console.error(err)
      })
  }, []);

  useEffect(() => {
    if (gerarCracha === false) { return null }
    if (formValues.curso) {
      api.get(`/curso/listar-convenios?procurar=${formValues.curso}`)
        .then(res => {
          setOptionsConvenio(res.data.map(value => ({
            label: value.convenio,
            value: value.convenio,
          })));
          setFormValues((prevValues) => ({
            ...prevValues,
            convenio: res.data.map(value => value.convenio)[0] || "",
            cor_convenio: res.data.map(value => value.cor_convenio)[0] || ""
          }));
        })
        .catch(err => console.error(err));
    }
  }, [formValues.curso]);

  const handleDownload = async (ref) => {
    const validateFormData = () => {
      const errors = [];
      if (!formValues.nome) errors.push("Nome não preenchido");
      if (showRM && !formValues.rm) errors.push("RM não preenchido");
      // if (showMatricula && !formValues.matricula) errors.push("Matrícula não preenchida!");
      if (showCourseAndConvenio && showRM && (!formValues.curso || !formValues.convenio)) { errors.push("Selecione um curso e um convênio"); }
      if (!formValues.fileManual) errors.push("Selecione uma imagem");
      if (!formValues.perfil || formValues.perfil === "0") errors.push("Selecione um perfil");
      if (showOutro && !formValues.outroPerfil) errors.push("Perfil não preenchido");
      return errors;
    };
    const errors = validateFormData();
    if (errors.length > 0) {
      Alert("Erro", errors.join(", "));
      return;
    }
    if (isAfter(new Date(), new Date(formValues.validade))) {
      Alert("Erro", "Data de validade já expirou");
      return;
    }
    setIsDownloading(true);
    try {
      const data = {
        ...formValues,
        validade: formValues.validade.replace(/-/g, "/")
      };
      await api.post("/usuario/log-gerarcracha", { data });
      const element = ref.current;
      if (!element) {
        throw new Error("Elemento não encontrado para captura");
      }
      await new Promise(resolve => setTimeout(resolve, 500));
      const canvas = await html2canvas(element, {
        logging: false,
        useCORS: true,
        scale: 2
      });
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: "mm",
        format: "a4",
      });
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 277, 0);
      pdf.save(`cracha_${formValues.nome}.pdf`);
      window.location.reload();
    } catch (error) {
      console.error("Falha no download da carteirinha:", error);
      Alert("Erro", `Falha no download da carteirinha: ${error.message}`);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const isAlunoPerfil = ['Aluno', 'Estagiário'].includes(formValues.perfil);
    const isProfessorPerfil = ['Coordenador', 'Professor', 'Funcionário'].includes(formValues.perfil);
    const shouldShowCourseAndConvenio = ['Professor', 'Coordenador', 'Aluno', 'Estagiário'].includes(formValues.perfil);
    const ShowOutro = ['Outro'].includes(formValues.perfil);

    setShowRM(isAlunoPerfil);
    setShowMatricula(isProfessorPerfil);
    setShowCourseAndConvenio(shouldShowCourseAndConvenio);
    setShowOutro(ShowOutro);
  }, [formValues.perfil]);

  return (
    <Contanier>
      {isDownloading && (
        <div className="Criadivanimation">
          <motion.div
            className="Criaload"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["0%", "0%", "50%", "50%", "0%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 1,
            }}
          />
        </div>
      )}
      {isDownloading ? (
        <div style={{ marginLeft: 5000, position: 'absolute', zIndex: 10 }}>
          <div ref={componentVerticalRef} style={{ width: 1200, height: 900 }}>
            <CriarCracha Dados={formValues} />
          </div>
        </div>
      ) : (
        <Card>
          <Header>
            <Buttons
              onClick={() => {
                setFormValues({})
                setGerarCracha(false)
              }}
              >
              <Icone name={"X"} color={" #3a3f86"} size={36} />
            </Buttons>
              <Title>Gerar Crachá</Title>
          </Header>
          <Forms>
            <InputSelect
              Label={"Perfil"}
              Options={optionsPerfil}
              Style1={{ color: "black" }}
              Style2={{ color: "black", border: "1px solid black", borderRadius: "50px", width: "30vh" }}
              Default={"-"}
              OnChange={(e) => setFormValues((prevValues) => ({
                ...prevValues,
                perfil: e.target.value
              }))}
            />
            <AnimatedField $show={showOutro}>
              <Input
                width="30vh"
                color="black"
                placeholdercolor="black"
                placeholder={formValues.outroPerfil === '' ? "Digite o Perfil" : "Novo Perfil"}
                required={true}
                style={{ alignitems: "center" }}
                event={(e) => setFormValues((prevValues) => ({
                  ...prevValues,
                  outroPerfil: e.target.value
                }))}
              />
            </AnimatedField>
            <Input
              width="30vh"
              color="black"
              placeholdercolor="black"
              placeholder="Nome"
              required={true}
              style={{ alignitems: "center" }}
              event={(e) => setFormValues((prevValues) => ({
                ...prevValues,
                nome: e.target.value
              }))}
            />
            <AnimatedField $show={showRM}>
              <Input
                width="30vh"
                color="black"
                placeholdercolor="black"
                placeholder="RM"
                required={true}
                style={{ alignitems: "center" }}
                event={(e) => setFormValues((prevValues) => ({
                  ...prevValues,
                  rm: e.target.value
                }))}
              />
            </AnimatedField>
            <AnimatedField $show={showMatricula}>
              <Input
                width="30vh"
                color="black"
                placeholdercolor="black"
                placeholder="Matricula"
                required={true}
                style={{ alignitems: "center" }}
                event={(e) => setFormValues((prevValues) => ({
                  ...prevValues,
                  matricula: e.target.value
                }))}
              />
            </AnimatedField>
            <AnimatedField $show={showCourseAndConvenio}>
              <InputSelect
                Style1={{ color: "black" }}
                Style2={{ color: "black", border: "1px solid black", borderRadius: "50px", width: "30vh" }}
                Label={`Curso`}
                Options={optionsCurso}
                Default={'-'}
                OnChange={(e) => setFormValues((prevValues) => ({
                  ...prevValues,
                  curso: e.target.value
                }))}
              />
              <InputSelect
                Style1={{ color: "black" }}
                Style2={{ color: "black", border: "1px solid black", borderRadius: "50px", width: "30vh", marginBottom: "1rem" }}
                Label={"Convênio"}
                Options={optionsConvenio}
                Default={formValues.convenio}
                value={optionsConvenio.find(option => option.value === formValues.convenio)?.label}
                OnChange={(e) => setFormValues((prevValues) => ({
                  ...prevValues,
                  convenio: e.target.value
                }))}
              />
            </AnimatedField>
            <Input
              placeholdercolor="black"
              placeholder="Validade"
              Style2={{ color: "#000" }}
              type={"date"}
              required={true}
              value={formValues.validade}
              min={format(minimo, 'yyyy-MM-dd')}
              event={(e) => setFormValues((prevValues) => ({
                ...prevValues,
                validade: e.target.value
              }))}
            />
            <DropDiv2>
              {formValues.fileManual ? (
                <ImageContainer>
                  <RemoveIcon
                    onClick={() => setFormValues((prevValues) => ({
                      ...prevValues,
                      fileManual: "",
                      fileImgManual: "",
                      tipoManual: ""
                    }))}
                  />
                  <Image
                    src={formValues.tipoManual.includes("image") ? formValues.fileImgManual : ""}
                    alt="Erro"
                  />
                </ImageContainer>
              ) : (
                <ImageContainer>
                  <FotoIcon />
                  <InputFileManual
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (
                        e?.target?.files[0]?.type.includes("image") ||
                        e?.target?.files[0]?.type.includes("pdf")
                      ) {
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          fileManual: e?.target?.files[0],
                          fileImgManual: URL.createObjectURL(e?.target?.files[0]),
                          tipoManual: e?.target?.files[0]?.type
                        }));
                      } else {
                        Swal.fire({
                          title: "Atenção!",
                          text: "Formato não permitido!",
                          icon: "warning",
                        })
                      }
                    }}
                  />
                </ImageContainer>
              )}
            </DropDiv2>
          </Forms>
          <Button
            id={"button"}
            width="8rem"
            height="3.5rem"
            event={() => { handleDownload(componentVerticalRef) }}
            placeholder="Gerar"
          />
        </Card>
      )}
    </Contanier>
  );
}
