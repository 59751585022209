import React, { useEffect, useState, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import Swal from 'sweetalert2';
import api from '../../../Services/api';
import Button from '../../Button';
import CheckBox from '../../Checkbox';
import { Alert } from '../../../utils/Alert';
import GerarPDF from '../../../utils/GerarPdfs';
import { Background, Cardo, ContainerTable, Table, Theader, Th, Tbody, Trbody, Td, DivTitle, Title, DivText, DivSave, DragAndDropArea2 } from './style';
import './styleCard.css';

const CardGerarResultadoProgressao = ({ show, setShow, progressaoEdit }) => {
  if (!show) return null;
  const [alunos, setAlunos] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [alunosStatus, setAlunosStatus] = useState({});
  const [alunosConceitos, setAlunosConceitos] = useState([]);
  const [initialAlunosStatus, setInitialAlunosStatus] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  const ListarAlunosProgressoes = useCallback(async (id_progressao) => {
    setAlunos([]);
    try {
      const response = await api.get(`/professor/listar-alunos-progressoes?id_progressao=${id_progressao}`);
      const { alunosEmProgressao, AlunosStatus } = response.data;
      const dadosUnificados = alunosEmProgressao.flat().map(aluno => {
        const status = AlunosStatus.flat().find(status => status.id_aluno === aluno.id) || {};
        return { ...aluno, cumpriu: status.cumpriu, conceito: status.conceito };
      });
      setAlunos(dadosUnificados);
      const inicialStatus = dadosUnificados.reduce((acc, aluno) => {
        acc[aluno.rm] = {
          id_progressao: progressaoEdit.id,
          id_aluno: aluno.id,
          entrege: aluno.cumpriu == 1 ? true : false,
          status: aluno.cumpriu == 1 && aluno.conceito == 'apto' ? 'apto' :
            (aluno.cumpriu == 1 || aluno.cumpriu == 0) && aluno.conceito == 'inapto' ? 'inapto' : null
        };
        return acc;
      }, {});
      setAlunosStatus(inicialStatus);

      const conceitos = dadosUnificados.map(aluno => aluno.conceito).filter(conceito => conceito !== 'Não Avaliado');
      setAlunosConceitos(conceitos.length > 0 ? conceitos[0] : 'Não Avaliado');
    } catch (err) {
      console.error("Erro ao listar alunos:", err);
      Swal.fire({
        title: "Erro",
        text: err.response?.data?.erro || "Erro interno de servidor",
        icon: "error",
        iconColor: "#ff0000",
        confirmButtonColor: "var(--azul)",
        confirmButtonText: "Confirmar",
      }).then((result) => { if (result.isConfirmed) setShow(false) });
    }
  }, [progressaoEdit.id, setShow]);

  const updateAlunoStatus = (rm, updates) => {
    setAlunosStatus(prevState => ({
      ...prevState,
      [rm]: { ...prevState[rm], ...updates }
    }));
  };

  const handleCheckboxChange = (type, id_progressao, id_aluno, rm) => {
    switch (type) {
      case 'entrege':
        updateAlunoStatus(rm, {
          id_progressao,
          id_aluno,
          entrege: !alunosStatus[rm]?.entrege,
          status: !alunosStatus[rm]?.entrege ? 'inapto' : null,
        });
        break;
      case 'apto':
        if (alunosStatus[rm]?.entrege) {
          updateAlunoStatus(rm, {
            id_progressao,
            id_aluno,
            status: alunosStatus[rm]?.status === 'apto' ? null : 'apto',
          });
        }
        break;
      case 'inapto':
        updateAlunoStatus(rm, {
          id_progressao,
          id_aluno,
          status: alunosStatus[rm]?.status === 'inapto' ? null : 'inapto',
        });
        break;
      default:
        break;
    }
  };

  const handleEditSave = async () => {
    const result = await Swal.fire({
      title: "Alerta",
      text: "Deseja salvar a Edição?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#ff735c",
      iconColor: "#ffae00",
      confirmButtonColor: "var(--azul)",
      confirmButtonText: "Confirmar",
    });

    if (result.isConfirmed) {
      setUploading(true);
      try {
        const formattedData = Object.values(alunosStatus)
          .filter(status => status.id_aluno && status.id_aluno !== 'undefined')
          .map(({ id_progressao, id_aluno, status, entrege }) => ({
            id_progressao,
            id_aluno,
            status: status === 'apto' ? 'apto' : 'inapto',
            entrege: entrege ? 1 : 0
          }));

        await api.put('/professor/resultado-alunos-progressao', { alunosStatus: formattedData });
        await ListarAlunosProgressoes(progressaoEdit.id);

        const downloadResult = await Swal.fire({
          title: "Sucesso",
          text: "Alunos avaliados com sucesso. Deseja baixar o resultado?",
          icon: "success",
          showCancelButton: true,
          cancelButtonText: "Não",
          cancelButtonColor: "#d31212",
          iconColor: "#09c712",
          confirmButtonColor: "#3d88df",
          confirmButtonText: "Sim",
        });

        if (downloadResult.isConfirmed) {
          const updatedAlunos = await new Promise(resolve => {
            setAlunos(currentAlunos => {
              resolve(currentAlunos);
              return currentAlunos;
            });
          });

          await GerarPDF(updatedAlunos, conteudoPDF);
          setShow(false);
        } else if (downloadResult.dismiss === Swal.DismissReason.cancel) {
          setHasChanges(false);
          setInitialAlunosStatus(JSON.parse(JSON.stringify(alunosStatus)));
        }
      } catch (err) {
        console.error('Erro ao salvar resultados:', err);
        Alert("Erro", err.response?.data?.erro || "Erro ao salvar resultados");
      } finally {
        setUploading(false);
      }
    }
  };

  const dataFim = new Date(progressaoEdit.data_fim);
  const terminou = new Date() > dataFim;
  const conteudoPDF = {
    titulo: 'Resultados da Progressão Parcial',
    curso: progressaoEdit.curso_rh?.split(', ')[1],
    turma: progressaoEdit.turma_rh?.split(", ")[1],
    disciplina: progressaoEdit.disciplina_rh?.split(', ')[1],
    DataInicio: progressaoEdit.data_inicio ? format(parseISO(progressaoEdit.data_inicio), "dd/MM/yyyy") : 'N/A',
    DataFim: progressaoEdit.data_fim ? format(parseISO(progressaoEdit.data_fim), "dd/MM/yyyy") : 'N/A',
    professor: progressaoEdit.professor?.split(', ')[1]
  };

  useEffect(() => {
    if (show) {
      ListarAlunosProgressoes(progressaoEdit.id);
    }
  }, [show, progressaoEdit.id, ListarAlunosProgressoes]);

  useEffect(() => {
    if (show) {
      setInitialAlunosStatus(JSON.parse(JSON.stringify(alunosStatus)));
    }
  }, [alunos]);

  useEffect(() => {
    if (show) {
      const hasStatusChanged = JSON.stringify(alunosStatus) !== JSON.stringify(initialAlunosStatus);
      setHasChanges(hasStatusChanged);
    }
  }, [alunosStatus, initialAlunosStatus]);

  return (
    <Background>
      <Cardo>
        <DivTitle><Title>Resultado Progressão Parcial</Title></DivTitle>
        <ContainerTable>
          {alunos.length > 0 ? (
            <Table>
              <Theader>
                <tr>
                  <Th>Rm</Th>
                  <Th>Nome</Th>
                  <Th>Entregue</Th>
                  <Th>Apto</Th>
                  <Th>Inapto</Th>
                </tr>
              </Theader>
              <Tbody>
                {alunos.map((aluno) => (
                  <Trbody key={aluno.rm}>
                    <Td>{aluno.rm}</Td>
                    <Td>{aluno.nome_aluno}</Td>
                    <Td>
                      <CheckBox
                        checked={alunosStatus[aluno.rm]?.entrege === true}
                        event={() => handleCheckboxChange('entrege', progressaoEdit.id, aluno.id, aluno.rm)}
                      />
                    </Td>
                    <Td>
                      <CheckBox
                        checked={alunosStatus[aluno.rm]?.status === 'apto'}
                        event={() => handleCheckboxChange('apto', progressaoEdit.id, aluno.id, aluno.rm)}
                        disabled={!alunosStatus[aluno.rm]?.entrege}
                      />
                    </Td>
                    <Td>
                      <CheckBox
                        checked={alunosStatus[aluno.rm]?.status === 'inapto'}
                        event={() => handleCheckboxChange('inapto', progressaoEdit.id, aluno.id, aluno.rm)}
                      />
                    </Td>
                  </Trbody>
                ))}
              </Tbody>
            </Table>
          ) : (
            <>
              <Table>
                <Theader>
                  <tr>
                    <Th>Rm</Th>
                    <Th>Nome</Th>
                    <Th>Apto/Inapto</Th>
                    <Th>Conceito</Th>
                  </tr>
                </Theader>
              </Table>
              <DivText>Nenhum Aluno Encontrado</DivText>
            </>
          )}
        </ContainerTable>
        <DivSave>
          <Button
            event={() => setShow(false)}
            width="9rem"
            placeholder="Fechar"
            backgroundcolor="#1a2e35"
            borderRadius="10px"
          />
          {terminou && alunosConceitos !== null && !hasChanges ? (
            <Button
              event={() => GerarPDF(alunos, conteudoPDF)}
              width="10rem"
              placeholder="Gerar PDF"
              backgroundcolor="#1a2e35"
              borderRadius="10px"
            />
          ) : (
            <DragAndDropArea2>Salve Para Gerar o PDF</DragAndDropArea2>
          )}
          <Button
            event={handleEditSave}
            width="12rem"
            placeholder={uploading ? "Salvando..." : "Salvar"}
            backgroundcolor="#1a2e35"
            borderRadius="10px"
            disabled={uploading}
          />
        </DivSave>
      </Cardo>
    </Background>
  );
};

export default CardGerarResultadoProgressao;