import Swal from "sweetalert2"
import api from "./api"
import { async } from "q"
import { Alert } from "../utils/Alert"

export const SendImageApi = (data, File) => {
  const formData = new FormData()

  Object.keys(data).map((key) => {
    formData.append(key, data[key])
  })
  formData.append("file", File)

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  }

  api
    .post(`/aluno/cadastrar-imagens`, formData, config)
    .then(async (res) => {})
    .catch((e) => {
      // Swal.fire({ title: "Atenção!", text: e, icon: "error" })
      console.log('SendImageApi', e);
      //   window.location.reload()
    })
}

export const UpdateImageApi = (rm, file) => {
  const formData = new FormData();
  
  formData.append("rm", rm);
  formData.append("file", file);

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  api
    .patch(`/aluno/atualizar-foto`, formData, config)
    .then(async (res) => {
      // await Swal.fire({
      //   title: "Sucesso",
      //   text: 'Foto atualizada com sucesso',
      //   icon: "success",
      //   iconColor: "#008000",
      //   confirmButtonText: "OK",
      //   confirmButtonColor: "	#0080ff",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     window.location.reload()
      //   }
      // })
    })
    .catch((e) => {
      console.log('UpdateImage',e);
      Alert("Erro","Erro ao atualizar a foto")
      // window.location.reload() // Se necessário
    });
};
