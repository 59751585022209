import React, { useEffect, useState } from "react"
import { AlignJustify } from "lucide-react"
import { useNavigate } from "react-router-dom"

import { Buttons, Container, ContainerMobile, Menu } from "./style"
import { MenuSecretaria } from "../../Components/Responsivo/MenuSecrtaria"
import Cracha from "../../Components/Cracha"
import Button from "../../Components/Button"
import ResetarSenha from "../../Components/modals/ResetarSenha"
import CrachaMobile from "../../Components/Responsivo/CrachaMobile"
import CriaCarterinha from "../../Components/modals/GerarCracha"

export default function HomeSecretaria() {
  const Navigate = useNavigate()
  const [resetEvent, setResetEvent] = useState(false)
  const [gerarCracha, setGerarCracha] = useState(false)
  const [open, setOpen] = useState(false)
  const [range, setRange] = React.useState(window.innerWidth)
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (windowSize.width > 1100) {
    return (
      <>
        <Container>
          <ResetarSenha resetEvent={resetEvent} setResetEvent={setResetEvent} />
          <CriaCarterinha gerarCracha={gerarCracha} setGerarCracha={setGerarCracha} />
          <CrachaMobile />
          <Buttons>
            <Button
              width={308}
              event={() => {
                Navigate("/visualizaralunos")
              }}
              placeholder="Alunos"
            />
            <Button
              width={308}
              event={() => {
                Navigate("/visualizarusuarios")
              }}
              placeholder="Usuários"
            />
            <Button
              width={308}
              event={() => {
                setGerarCracha(true)
              }}
              placeholder="Gerar Crachá"
            />
            <Button
              width={308}
              event={() => {
                Navigate("/vercarterinhas")
              }}
              placeholder="Controle de Acessos"
            />
            <Button
              width={308}
              event={() => {
                setResetEvent(true)
              }}
              placeholder="Resetar Senha"
            />
            {/* <Button
              width={308}
              event={() => {
                Navigate("/desativaraluno")
              }}
              placeholder="Desativar Alunos"
            /> */}
            <Button
              width={308}
              event={() => {
                Navigate("/")
              }}
              placeholder="Sair"
            />
          </Buttons>
        </Container>
      </>
    )
  } else {
    return (
      <Container>
        <MenuSecretaria setOpen={setOpen} open={open} />
        <ResetarSenha resetEvent={resetEvent} setResetEvent={setResetEvent} />
        <ContainerMobile>
          <Menu>
            <AlignJustify
              color="black"
              size={40}
              onClick={() => {
                setOpen(true)
              }}
            />
          </Menu>
        </ContainerMobile>
        <CrachaMobile />
      </Container>
    )
  }
}
