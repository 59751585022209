import React from "react"
import "./style.css"
export default function Button(props) {
  return (
    <button
      id={props.id}
      className="button"
      onClick={props.event}
      disabled={props.disabled}
      style={{
        marginBottom: props.marginBottom,
        marginRight: props.marginright,
        marginLeft: props.marginleft,
        marginTop: props.margintop,
        width: props.width,
        height: props.height,
        border: props.border,
        backgroundColor: props.backgroundcolor,
        color: props.colo,
        whiteSpace: props.whitespace,
        borderRadius: props.borderRadius,
        padding: props.padding,
        fontSize: props.fontsize,
      }}
    >
      {props.placeholder}
    </button>
  )
}

/* <Button
event={() => {

}}
placeholder="Sair"
width={}
height={}
margintop={}
/> */
