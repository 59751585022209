import React, { Component } from 'react'
import Button from '../../Components/Button'
import { useNavigate } from 'react-router-dom'
import { Container, LeftDiv, RightDiv, Titulo, Card, TitleHolder } from './style'
import User from "../../assets/images/user.png"

export default function ProfessorHome() {

  const Navigate = useNavigate()

  return (
    <Container>
      <LeftDiv>
        <Card>
          <TitleHolder>
            <Titulo>HUB Professor</Titulo>
          </TitleHolder>
          <div style={{ width: "fit-content" }}>
            <img style={{ width: "300px" }} src={User} alt="perfil" className="pefil" />
          </div>
        </Card>
      </LeftDiv>
      <RightDiv>
        <Button
          width="250px"
          height="55px"
          placeholder="Progressão Parcial"
          event={() => Navigate("/progressao-parcial-professor")}
        />
        <Button
          width="250px"
          height="55px"
          placeholder="Sair"
          event={() => Navigate("/")}
        />
      </RightDiv>
    </Container>
  )

}