import React, { useEffect, useRef } from "react";
import "./style.css";

export default function CheckBox({ label, event, checked, cor, borda, weight, size }) {
  const checkboxRef = useRef(null);
  useEffect(() => {
    if (checked && checkboxRef.current) {
      checkboxRef.current.focus();
    }
  }, [checked]);

  return (
    <label className="material-checkbox" style={{color: cor, fontWeight: weight, fontSize: size}}>
      <input
        type="checkbox"
        onChange={event}
        checked={checked}
        ref={checkboxRef}
        style={{color: cor}}
      />
      <span className="checkmark" style={{borderColor: borda}}></span>
      {label}
    </label>
  );
}
