import React, { useState } from "react"
import { Buttons, Card, Cards, Checkb, Close, Contanier, Forms, Header, LabelTermos, Title } from "./style"
import Input from "../../Input"
import Button from "../../Button"
import img from "../../../assets/images/demostração.png"
import Icone from "../../../utils/Icone"

export default function ShowImagem({ showImagem, setShowImagem }) {
  if (showImagem == true) {
    return (
      <Contanier>
        <Cards>
          <Close
            onClick={() => {
              setShowImagem(false)
            }}
          >
            <Icone name={"X"} color={" var(--azul)"} size={36} />
          </Close>
          <img src={img} alt="tscg" style={{ marginTop: "15%", width: "90%", marginLeft: "5%" }} />
        </Cards>
      </Contanier>
    )
  } else if (setShowImagem == false) {
    return null
  }
}
