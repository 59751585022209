import styled from "styled-components"

export const Container = styled.div`
  position: absolute;
  top: 11vh;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
  background-color: white;

  @media(max-width: 1200px) {
    gap: 3rem;
  }
  @media(max-width: 400px) {
    gap: 0;
  }
  @media(max-height: 700px) {
    top: 12vh;
    height: 107vh;
    gap: 1;
  }
  @media(max-height: 300px) {
    height: 150vh;
  }
`

export const Lista = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 3rem;
  margin-bottom: 2%;

  @media(max-height: 700px) {
    padding-bottom: 2%;
  }
`

export const Buttons = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: right;
  color: "black";

  @media(max-width: 1200px){
    margin-top: 1.5%;
    margin-right: 10%;
  }
  @media(max-width: 800px){
    margin-top: 3%;
    margin-right: 9%;
  }
`

export const Item = styled.button`
  width: 170%;
  border-radius: 1rem;
  height: 7vh;
  background-color: black;
  color: white;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: 0.5s ease-in;

  &:hover {
    transform: translateY(-20deg);
    background-color: purple;
  }

  @media(max-height: 700px) {
    height: 12vh;
  }
`
