import styled from "styled-components"
import { FaEdit, FaExternalLinkAlt, FaTrashAlt } from "react-icons/fa"

export const Container = styled.div`
  width: 90%;
  margin-left: 5%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // border:5px solid black;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const ContainerTable = styled.div`
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 5px solid #1a2e35;
  // border:5px solid blue;
  // margin-top:20px;
`
export const Title = styled.h1`
  padding: 10px 20px;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  margin-top: 1.5%;
  font-weight: 200;
  font-size: #1a2e35;
  border-radius: 20px 20px 0px 0px;
`
export const Menus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`

export const EditButton = styled(FaEdit)`
  cursor: pointer;
  margin-right: 1.4rem;
  margin-left: 1.4rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
  overflow: scroll;
`
export const Theader = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: 0;
  height: 8vh;
  font-size: 1rem;
  z-index: 9;
`
export const Tbody = styled.tbody`
  height: fit-content;
`

export const Th = styled.th`
  padding: 2px 15px;
  text-align: center;
`
export const Td = styled.td`
  padding: 2px 25px;
  text-align: start;
`

export const Trbody = styled.tr`
  overflow: scroll;
  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  transition: all 0.2s;

  &:hover {
    background-color: grey;
    color: white;
    transform: scale(1.01);
  }
`
export const Colletion = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 40%;
  height: 90%;
`
export const Buttons = styled.div`
  margin-left: 2vh;
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  gap: 20px;
  // justify-content: space-between;
  // height: 23%;
`
export const Grup = styled.div`
  align-items: center;
  justify-content: start;
  gap: 2rem;
  width: 80%;
  display: flex;
`
export const DeleteButton = styled(FaTrashAlt)`
  cursor: pointer;
  margin-right: 1.4rem;
  margin-left: 1.4rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`
