import React, { useEffect, useState } from "react"
import {
  Container,
  FirstCard,
  SecondCard,
  Form,
  Field,
  Heading,
  Mfield2,
  Mfield1,
  Spacediv,
  DropDiv,
  Heading2,
  Icon,
  InputFile,
  DropDiv2,
  InputFileManual,
  Button1,
  Button2,
  Buttons,
  ButtonsDiv,
  ImageContainer,
  RemoveIcon,
  Image,
  Imagecsv,
  Arroba,
} from "./style"

import Input from "../../Input"
import api from "../../../Services/api"
import { useNavigate } from "react-router-dom"
import Button from "../../Button"
import Swal from "sweetalert2"
import { Alert } from "../../../utils/Alert"
import InputSelect from "../../Select"
import { SendAnexoApi } from "../../../Services/sendAnexo"
import { SendImageApi } from "../../../Services/sendImage."
import ExportDate from "../../../Services/exportDate"
import Icone from "../../../utils/Icone"

export default function CadastroManual({ setShow, Show }) {
  const [Nome, setNome] = useState("")
  const [RM, setRM] = useState("")
  const [Email, setEmail] = useState("")
  const [Curso, setCurso] = useState("")
  const [DataNascimento, setDataNascimento] = useState("")
  const [DataInicio, setDataInicio] = useState("")
  const [DataTermino, setDataTermino] = useState("")
  const [Convenio, setConvenio] = useState("")
  const [NomeAnexo, setNomeAnexo] = useState("")

  const [FileImg, setFileImg] = useState("")
  const [NomeArquivo, setNomeArquivo] = useState("")
  const [Tipo, setTipo] = useState("")
  const [File, setFile] = useState("")

  const [FileManual, setFileManual] = useState("")
  const [NomeArquivoManual, setNomeArquivoManual] = useState("")
  const [TipoManual, setTipoManual] = useState("")
  const [FileImgManual, setFileImgManual] = useState("")
  const [PdfImage, setPdfImage] = useState("")
  const [XlsImage, setXlsImage] = useState("")

  const [optionsCurso, setOptionsCurso] = useState([])
  const [optionsConvenios, setoptionsConvenios] = useState([])

  const Navigate = useNavigate()

  const [Estado, setEstado] = useState(true)

  useEffect(() => {
    async function PegaCursos() {
      await api
        .get("/curso/listar?procurar=")
        .then((res) => {
          const { data } = res
          const resultsCurso = []
          data.forEach((value) => {
            resultsCurso.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsCurso([{ label: " - ", value: null }, ...resultsCurso])
        })
        .catch((err) => {
          console.log("deu erro X--> ", err)
        })
    }
    PegaCursos()
  }, [])

  useEffect(() => {
    if (Curso == "") {
      console.log("Não ativou, curso vazio")
    } else {
      api
        .get(`/curso/listar-convenios?procurar=${Curso}`)
        .then((res) => {
          const data = res.data
          const resultsConvenios = [
            {
              label: " - ",
              value: "",
            },
          ]
          data.forEach((value) => {
            resultsConvenios.push({
              label: value.convenio,
              value: value.convenio,
            })
          })
          setoptionsConvenios(resultsConvenios)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [Curso])

  const vazio = [{ label: "Selecione o curso", value: "" }]

  function setAllFalse() {
    setNome("")
    setRM("")
    setEmail("")
    setDataNascimento("")
    setDataInicio("")
    setDataTermino("")
    setCurso("")
    setConvenio("")
    setFileManual("")
    setNomeArquivoManual("")
    setTipoManual("")
    setFileImgManual("")
  }
  // Obtém a data atual
  const hoje = new Date();
  // Formata o ano com quatro dígitos
  const ano = hoje.getFullYear().toString().padStart(4, '0');
  // Formata o mês com dois dígitos
  const mes = (hoje.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna 0-11, então adicione 1
  // Formata o dia com dois dígitos
  const dia = hoje.getDate().toString().padStart(2, '0');
  // Constrói a string no formato desejado
  const dataDeHojeFormatada = `${ano}-${mes}-${dia}`;

  function HandleManualSubmit(e) {
    const data = {
      email: Email,
      rm: RM,
      nome: Nome,
      dt_nascimento: DataNascimento,
      dt_inicio: DataInicio,
      dt_termino: DataTermino,
      id_curso: Curso,
      convenio: Convenio,
    }
    if (File) {
      const data = {
        tituloDocumento: Tipo,
        NomeArquivo: NomeArquivo,
      }
      SendAnexoApi(data, File)
      setNomeArquivo("")
      setTipo("")
      setFile("")
      setNomeAnexo("")
    } else {
      if (!data.rm) {
        Alert('Alert2', 'Insira o RM do aluno');
        return
      } else if (!data.nome) {
        Alert('Alert2', 'Insira o Nome do aluno.');
        return
      } else if (!data.email) {
        Alert('Alert2', 'Insira o E-mail do aluno.');
        return
      } else if (!data.dt_nascimento) {
        Alert('Alert2', 'Insira a Data de Nascimento.');
        return
      } else if (data.dt_nascimento >= dataDeHojeFormatada) {
        Alert('Alert2', 'Data de Nascimento Inválida.')
        return
      } else if (!data.id_curso) {
        Alert('Alert2', 'Escolha um Curso');
        return
      } else if (!data.convenio) {
        Alert('Alert2', 'Escolha um Convenio');
        return
      } else if (!data.dt_inicio) {
        Alert('Alert2', 'Insira a Data de Início.');
        return
      } else if (!data.dt_termino) {
        Alert('Alert2', 'Insira a Data de Término.');
        return
      } else if (DataInicio === data.dt_termino || data.dt_inicio > data.dt_termino) {
        Alert('Alert2', 'Data de Início ou Término Inválida.')
        return
      } else if (RM !== "" && Email !== "" && Nome !== "" && DataNascimento !== "" && DataInicio !== "" && DataTermino !== "" && Convenio !== "" && Curso !== "") {
        api
          .post("/aluno/cadastrar", data)
          .then((res) => {
            Swal.fire({
              title: "Concluido",
              text: `${res.data.mensagem}`,
              icon: "success",
              iconColor: "#008000",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            })
            const data = {
              tituloDocumento: TipoManual,
              NomeArquivo: RM,
            }
            SendImageApi(data, FileManual)
            setAllFalse()
            setTimeout(() => { window.location.reload() }, 1600);
          })
          .catch((err) => {
            console.log("erro: ", err)
            Swal.fire({
              title: "Erro",
              text: `${err.response.data.erro.split(".")[0]}`,
              icon: "error",
              iconColor: "red",
              confirmButtonColor: "var(--azul)",
              confirmButtonText: "Confirmar",
            })
          })
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Preencha todos os campos",
        })
      }
    }
  }

  if (Show == true) {
    return (
      <FirstCard Estado={Estado}>
        <Arroba
          onClick={() => {
            setAllFalse()
            setEstado(false)
            Navigate("/visualizaralunos")
            setTimeout(() => {
              setShow(false)
              setEstado(true)
            }, 400)
          }}
        >
          <Icone name={"X"} color={"#F0F0F0"} size={46} />
        </Arroba>
        <Form>
          <Heading>Cadastro do Aluno</Heading>
          <Field>
            <Mfield1>
              <Input
                marginleft="auto"
                marginright="auto"
                width="20vh"
                color="#FFFFFF"
                type={"Number"}
                placeholder={"RM"}
                required={"true"}
                value={RM}
                event={(e) => setRM(e.target.value)}
              />
              <br />
              <br />
              <Input
                marginleft="auto"
                marginright="auto"
                width="20vh"
                color="#FFFFFF"
                type={"text"}
                placeholder={"Nome"}
                required={"true"}
                value={Nome}
                event={(e) => setNome(e.target.value)}
              />
              <br />
              <br />
              <Input
                marginleft="auto"
                marginright="auto"
                width="20vh"
                color="#FFFFFF"
                type={"text"}
                placeholder={"Email"}
                required={"true"}
                value={Email}
                event={(e) => setEmail(e.target.value)}
              />
              <br />
              <br />
              <Input
                marginleft="auto"
                marginright="auto"
                width="20vh"
                color="#FFFFFF"
                type={"date"}
                placeholder={"Nascimento"}
                required={"true"}
                value={DataNascimento}
                event={(e) => setDataNascimento(e.target.value)}
              />
            </Mfield1>
            <Mfield2>
              <InputSelect
                Label={"Curso"}
                Options={optionsCurso}
                Default={"-"}
                OnChange={(e) => {
                  setCurso(e.target.value)
                }}
              />
              <br />
              <br />
              <InputSelect
                Label={"Convênio"}
                Options={Curso == "" ? vazio : optionsConvenios}
                Default={"-"}
                OnChange={(e) => {
                  setConvenio(e.target.value)
                }}
              />
              <br />
              <br />
              <Input
                marginleft="auto"
                marginright="auto"
                width="20vh"
                color="#FFFFFF"
                type={"date"}
                placeholder={"Início"}
                required={"true"}
                value={DataInicio}
                event={(e) => setDataInicio(e.target.value)}
              />
              <br />
              <br />
              <Input
                marginleft="auto"
                marginright="auto"
                width="20vh"
                color="#FFFFFF"
                type={"date"}
                placeholder={"Término"}
                required={"true"}
                value={DataTermino}
                event={(e) => setDataTermino(e.target.value)}
              />
            </Mfield2>
          </Field>
          <label style={{ color: "var(--background)", marginTop: "1.5rem" }}>Foto do Aluno</label>
          <br />
          <DropDiv2>
            {FileManual ? (
              <ImageContainer>
                <RemoveIcon
                  onClick={() => {
                    setFileManual("")
                    setFileImgManual("")
                    setTipoManual("")
                    setNomeArquivoManual("")
                  }}
                />
                <Image
                  src={
                    TipoManual.includes("image")
                      ? FileImgManual
                      : TipoManual.includes("pdf")
                        ? PdfImage
                        : TipoManual.includes("sheet")
                          ? XlsImage
                          : ""
                  }
                  alt="Erro"
                />
              </ImageContainer>
            ) : (
              <InputFileManual
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (
                    e?.target?.files[0]?.type.includes("image") ||
                    e?.target?.files[0]?.type.includes("png") ||
                    e?.target?.files[0]?.type.includes("jpeg") ||
                    e?.target?.files[0]?.type.includes("pdf") ||
                    e?.target?.files[0]?.type.includes("sheet") ||
                    e?.target?.files[0]?.type.includes("jpg")
                  ) {
                    setFileImgManual(URL.createObjectURL(e?.target?.files[0]))
                    setFileManual(e?.target?.files[0])
                    setTipoManual(e?.target?.files[0]?.type)
                    setNomeArquivoManual(e?.target?.files[0]?.name)
                  } else {
                    Swal.fire({
                      title: "Atenção!",
                      text: "Formato não permitido!",
                      icon: "warning",
                    })
                  }
                }}
              ></InputFileManual>
            )}
          </DropDiv2>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 30,
            position: "absolute",
            width: "20%",
            top: "80%",
            left: "78%",
            heigh: "50vh",
          }}
        >
          <Button
            width="55%"
            height="40%"
            placeholder="Enviar"
            event={(e) => {
              HandleManualSubmit(e)
            }}
          />
        </div>
      </FirstCard>
    )
  } else {
    return null
  }
}
