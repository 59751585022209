import React, { useState, useRef, useEffect } from "react"
import "./style.css"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { AlignJustify, GalleryHorizontalEnd, GalleryVerticalEnd, Redo, RefreshCw } from "lucide-react"
import { useNavigate, useParams } from "react-router-dom"
import { motion, useUnmountEffect } from "framer-motion"
import Swal from "sweetalert2"
import { Buttons, Container,  Menu, MainDiv, ContainerMenu } from "./style"
import { MenuAluno } from "../../Components/Responsivo/MenuAluno"
import Button from "../../Components/Button"
import PrimeiroAcesso from "../../Components/modals/PrimeiroAcesso"
import CarterinhaMobile from "../../Components/Responsivo/CarterinhaMobile"
import api from "../../Services/api"
import CarterinhaMobileS from "../../Components/Responsivo/CarterinhaMobileS"
import CarteirinhaPdfVert from "../../Components/Pdf/carteirinhaSecreVerti"

export default function VerCarterinha() {
  const Navigate = useNavigate()
  const token = localStorage.getItem("token")
  const [range, setRange] = useState(window.innerWidth)
  const breakpoint = 700
  const [idUser, setIdUser] = useState([])
  const [firstacess, setFirstAcess] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [donwload, setDownload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const [isDownloadVertical, setIsDownloadVertical] = useState(false)
  const [open, setOpen] = useState(false)
  const componenteRef = useRef(null)
  const componenteVerticalRef = useRef(null)
  const componenteMobile = useRef(null)
  const firstAcess = localStorage.getItem("Entrada")

  useEffect(() => {
    setRange(window.innerWidth)
    api
      .get("/aluno/perfil", token)
      .then((response) => {
        setIdUser(response.data.id)
      })
      .catch((err) => {
        console.log(err)
      })
  })
  useEffect(() => {
    setFirstAcess(firstAcess)
  }, [])

  const takeMenuDonwload = async (prop) => {
    setDisabled(true)
    setDownload(prop)
    await Swal.fire({
      icon: "success",
      title: "Donwload em andamento, aguarde...",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 1200,
    }).then(async (res) => {
      api.get(`/log/entrar-aluno?id_aluno=${idUser}`)
      setLoading(true)
      const element =  componenteMobile.current
      const canvas = await html2canvas(element)
      setTimeout(() => {
        if (element) {
          const pdfPortrait = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
          })

          const imgData = canvas.toDataURL("image/png")

          pdfPortrait.addImage(imgData, "PNG", 20, 20, 200, 0)

          pdfPortrait.save("carterinha.pdf")
        }
        setDownload(false)
        setLoading(false)
      }, 9000)
    })
    setDisabled(false)
  }
  const downloadVertical = async () => {
    setDisabled(true);
    setIsDownloadVertical(true);

    await Swal.fire({
      icon: "success",
      title: "Download em andamento, aguarde...",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 1200,
    }).then(async (res) => {
      setLoading(true);

      // Cria um novo documento PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
   //Colocado imagen gerada no PDF
   const element = componenteVerticalRef.current
   const canvas = await html2canvas(element)
   const imgWidth = canvas.width;
   const imgHeight = canvas.height;

   // Calcula as proporções da área do PDF onde a imagem será adicionada
   const pdfWidth = pdf.internal.pageSize.getWidth();
   const pdfHeight = pdf.internal.pageSize.getHeight();

   // Calcula as proporções para manter a imagem dentro das dimensões do PDF
   const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

   // Calcula as novas dimensões da imagem para manter a proporção
   const newWidth = imgWidth * ratio;
   const newHeight = imgHeight * ratio;
   pdf.addImage(canvas, "PNG", 2, 20, newWidth, newHeight);

      // Salva o PDF
      pdf.save("carterinha.pdf");
      setIsDownloadVertical(false);
      setLoading(false);
    });

    setDisabled(false);
  };

  const downloadHorizontal = async () => {
    setDisabled(true);
    setIsDownload(true);

    await Swal.fire({
      icon: "success",
      title: "Download em andamento, aguarde...",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 1200,
    }).then(async (res) => {
      setLoading(true);

      // Cria um novo documento PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      //Colocado imagen gerada no PDF
     const alement = await componenteMobile.current
      const canvas = await html2canvas(alement)
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Calcula as proporções da área do PDF onde a imagem será adicionada
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calcula as proporções para manter a imagem dentro das dimensões do PDF
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      // Calcula as novas dimensões da imagem para manter a proporção
      const newWidth = imgWidth * ratio;
      const newHeight = imgHeight * ratio;
      pdf.addImage(canvas, "PNG", 2, 20, newWidth, newHeight);


      // Salva o PDF
      pdf.save("carterinha.pdf");
      setIsDownload(false);
      setLoading(false);
    });

    setDisabled(false);
  };


  if (range > breakpoint) {
    return (
      <>
      <div style={{ marginLeft: 3000, marginBottom: -700,  overflowX: "hidden" }}>
        <div ref={componenteVerticalRef} style={{ width: 990, height: 700 }}>
          <CarteirinhaPdfVert />
        </div>
      </div>
        {loading == true ? (
          <div className="divanimation">
            <motion.div
              className="load"
              animate={{
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 180, 180, 0],
                borderRadius: ["0%", "0%", "50%", "50%", "0%"],
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 1,
              }}
            />
          </div>
        ) : null}

        {/* <MenuAluno open={open} setOpen={setOpen} /> */}
        {/* <PrimeiroAcesso firstacess={firstacess} setFirstAcess={setFirstAcess} /> */}
            <Container>
              <MainDiv>
                <CarterinhaMobileS />
                <Buttons>
                  <Button event={() => downloadVertical()} placeholder="Baixar Carteirinha" disabled={disabled} />
                  <Button
                    width={208}
                    event={() => {
                      Navigate("/secretaria")
                    }}
                    placeholder="Sair"
                    />
                </Buttons>
              </MainDiv>
            </Container>
      </>
    )
  } else {
    return (
      <>
        <MenuAluno open={open} setOpen={setOpen} senMenuDonwload={takeMenuDonwload} />
        <PrimeiroAcesso firstacess={firstacess} setFirstAcess={setFirstAcess} />
        {donwload ? (
          <div style={{ marginLeft: 0, overflowX: "hidden" }}>
            <div ref={componenteMobile} style={{ width: 1500 }}>
              <CarteirinhaPdfVert />
            </div>
          </div>
        ) : (
          <ContainerMenu>
            <Menu>
              <AlignJustify
                color="black"
                size={40}
                onClick={() => {
                  setOpen(true)
                }}
              />
            </Menu>
            <CarterinhaMobile />
          </ContainerMenu>
        )}
      </>
    )
  }
}
