import React from "react"
import Logo from "../../../assets/images/logocarterinha.png"
import User from "../../../assets/images/user.png"
import "./styleCria.css"
import { format, parseISO } from "date-fns"

export default function CriarCracha({ Dados }) {
  return (
    <div className="cardCria">
      <div className="card-border-topCria" style={{ backgroundColor: Dados.cor_convenio }} />
      <div className="infCria">
        <div className="imgCria">
          <img src={Dados.fileImgManual == "undefined" ? User : Dados.fileImgManual} alt="ImgUser" />
        </div>
        <h2 className="nomeCria" style={{ fontSize: Dados.nome.length >= 35 ? '1rem' : '1.2rem' }}>{Dados.nome}</h2>
        <div className="infDadosCriar" style={{ gap: Dados.convenio ? '5px' : '10px', height: '4.5rem' }}>
          {Dados.rm ? <span><p>RM:</p>{Dados.rm}</span> : Dados.matricula ? <span><p>Matrícula:</p>{Dados.matricula}</span> : null}
          {Dados.curso ? <span><p>curso:</p>{Dados.curso == "Desenhista da Construção Civil" ? 'Desenhista da Const. Civil' : Dados.curso}</span> : null}
          {Dados.convenio ? <span><p>convênio:</p>{Dados.convenio}</span> : null}
          {Dados.validade ? <span><p>validade:</p><h4>{format(parseISO(Dados.validade), 'dd/MM/yyyy')}</h4></span> : null}
        </div>
        <img src={Logo} alt="" srcset="" className="imagemCria" />
      </div>
      <div className="funcaoCria">
        <p style={{ backgroundColor: Dados.cor_convenio }}>{Dados.perfil === 'Outro' ? Dados.outroPerfil : Dados.perfil}</p>
      </div>
    </div>
  )
}
