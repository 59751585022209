import React from "react"
import Input from "../../Input"
import Button from "../../Button"
import { Buttons, Card, Contanier, Header, Table, Theader, Trbody, Td, Th, Title, Grup, Tbody, ContainerTable } from "./style"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"

export default function ResponseXlsx({ activedEvent, setActivedEvent, resRm, resNome }) {
  if (activedEvent) {
    const alunos = resRm.map((rm, index) => ({
      rm,
      nome: resNome[index] || 'Nome não disponível',
    }));
    return (
      <Contanier>
        <Card>
          <Header>
            <Title>Ver alunos</Title>
          </Header>
          <ContainerTable>
            <Table>
              <Theader>
                <tr>
                  <Th>RM</Th>
                  <Th>Nome</Th>
                </tr>
              </Theader>
              <Tbody>
                {alunos.map((aluno, i) => (
                  <Trbody key={i}>
                    <Td>{aluno.rm}</Td>
                    <Td>{aluno.nome}</Td>
                  </Trbody>
                ))}
              </Tbody>
            </Table>
          </ContainerTable>
          <Header>
            <Button
              placeholder='Fechar'
              event={() => {
                window.location.reload();
                setActivedEvent(false);
              }}
            />
          </Header>
        </Card>
      </Contanier>
    )
  } else {
    return null
  }
}
