import React, { Component } from 'react'
import Button from '../../Components/Button'
import { useNavigate } from 'react-router-dom'
import { Container, LeftDiv, RightDiv, Titulo, Card, TitleHolder } from './style'
import User from "../../assets/images/user.png"
import { FaUser } from 'react-icons/fa'

export default function CoordenadorHome() {

  const Navigate = useNavigate()

  return (
    <Container>
      <LeftDiv>
        <Card>
          <TitleHolder>
            <Titulo>HUB Coordenador</Titulo>
          </TitleHolder>
          <div style={{ width: "fit-content" }}>
            {/* <img src={User} alt="perfil" className="pefil"  */}
            <FaUser style={{ fontSize: "20rem", color:'#fff' }} />
          </div>
        </Card>
      </LeftDiv>
      <RightDiv>
        <Button
          width="250px"
          height="55px"
          colo={'#0048CC'}
          backgroundcolor={'#fff'}
          border={'2px solid #0048CC'}
          placeholder="Progressão Parcial"
          event={() => Navigate("/progressao-parcial-coordenador")}
        />
        <Button
          width="250px"
          height="55px"
          colo={'#0048CC'}
          backgroundcolor={'#fff'}
          border={'2px solid #0048CC'}
          placeholder="Sair"
          event={() => Navigate("/")}
        />
      </RightDiv>
    </Container>
  )

}