import React, { useEffect, useState } from "react"
import "./style.css"
import {
  Container,
  ContainerTable,
  Menus,
  Table,
  Theader,
  Trbody,
  Td,
  Th,
  Title,
  Buttons,
  Colletion,
  Grup,
  DeleteButton,
} from "./style"
import Button from "../../Components/Button"
import { useFetcher, useNavigate } from "react-router-dom"
import Pesquisar from "../../Components/Pesquisar"
import CheckBox from "../../Components/Checkbox"
import api from "../../Services/api"
import { format } from "date-fns/esm"
import CriarUsario from "../../Components/modals/CriarUsuario"
import { Alert } from "../../utils/Alert"
export default function VisualizarUsuarios() {
  const Navigate = useNavigate()
  const [initalUsuarios, setInitalUsuarios] = useState([])
  const [isActived, setIsActived] = useState(false)
  const [Usuarios, setUsuarios] = useState([])
  const [searchNome, setSearchNome] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [mostrarTodos, setMostrarTodos] = useState(250);
  const limite = 0.30  // 30%

  const sim = {
    Users: Usuarios,
  }

  useEffect(() => {
    api.get("/usuario/listar?procurar").then((res) => {
      setInitalUsuarios(res.data)
      setUsuarios(res.data)
      setMostrarTodos(res.data.length)
    })
  }, [])

  const normalizeString = (str) => {
    if (str === undefined || str === null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredUsuarios = Usuarios.filter((Usuarios) => {
    const searchNomeNormalized = normalizeString(searchNome);
    const searchEmailNormalized = normalizeString(searchEmail);

    const UsuariosNome = normalizeString(Usuarios.nome || "");
    const UsuariosEmail = normalizeString(Usuarios.email || "");

    return (
      UsuariosNome.includes(searchNomeNormalized) &&
      UsuariosEmail.includes(searchEmailNormalized)
    );
  }).slice(0, mostrarTodos);


  async function deletarUsuario(id, nome) {
    try {
      Alert("Alert", `Deseja deletar  o Usuário  ${nome} `).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/usuario/apagar?id_usuario=${id}`)
            .then((result) => {
              window.location.reload()
            })
            .catch((err) => {
              console.log(err)
              Alert("Error", "Ocorreu um erro ao desativar Usuário")
            })
        }
      })
    } catch (err) {
      console.log(err)
      Alert("Error", "Ocorreu um erro ao desativar Usuário")
    }
  }
  return (
    <>
      <CriarUsario isActived={isActived} setIsActived={setIsActived} />
      <Container>
        <Menus>
          <Title>Controle de Usuarios</Title>
          <Buttons>
            <Button
              event={() => {
                Navigate("/secretaria")
              }}
              width="12%"
              margintop="2%"
              placeholder="Voltar"
              backgroundcolor="#1a2e35"
              borderRadius="30px 30px 0px 0px"
            />
            <Button
              event={() => {
                setIsActived(true)
              }}
              width="12%"
              margintop="2%"
              backgroundcolor="#1a2e35"
              placeholder="Adicionar"
              borderRadius="30px 30px 0px 0px"
            />
          </Buttons>
        </Menus>
        <ContainerTable>
          <Table>
            <Theader>
              <tr>
                <Th>
                  <Grup>
                    nome{" "}
                    <Pesquisar
                      placeholder="Digite nome"
                      name="nome"
                      event={(e) => { setSearchNome(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    Email{" "}
                    <Pesquisar
                      placeholder="Digite Email"
                      name="email"
                      event={(e) => { setSearchEmail(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>{filteredUsuarios.length} Usuarios</Th>
              </tr>
            </Theader>
            <tbody>
              {filteredUsuarios?.map((i, d) => (
                <Trbody key={i}>
                  <Td>{i?.nome}</Td>
                  <Td>{i?.email}</Td>
                  <Td>
                    {" "}
                    <DeleteButton
                      title="Deletar Usuario"
                      onClick={() => {
                        deletarUsuario(i?.id, i?.nome)
                      }}
                    />
                  </Td>
                </Trbody>
              ))}
            </tbody>
          </Table>
        </ContainerTable>
      </Container>
    </>
  )
}
