import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
    :root{
        /**
        * Paleta escolhida: https://coolors.co/palette/3d5a80-98c1d9-e0fbfc-ee6c4d-293241
        * 
        * #D90000 -- vermelho fiec
        * #030536 -- azul uniforme
        * #FFFFFF -- white basico
        * #2DD730 -- Lime Green
        */

        /** Nome das cores https://www.color-name.com */
        --amarelo: rgb(255,214,100);
        --background: #FFFFFF;
        --azul: #315285;
        --vermelhofiec: #D90000;
        --blur-bg-color: rgba(0, 0, 0, 0.5);
        --alerta-azul-color: #030536;
        --swal-confirm-button-color: #2DD730;
        --swal-cancel-button-color: #D90000;
        --button-text-color: #293241;
    }
    *{
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        
    //     /* animations */
    //     @keyframes slide-top {
    //         0% {
    //             transform: translateY(100px);
    //         }
    //         100% {
    //             transform: translateY(0);
    //         }
    //     }
    // }
    // html {
    //     -webkit-box-sizing: border-box;
    //     -moz-box-sizing: border-box;
    //     box-sizing: border-box;
    // }
    // *, *:before, *:after {
    //     -webkit-box-sizing: inherit;
    //     -moz-box-sizing: inherit;
    //     box-sizing: inherit;
    }
    body {
        // transition: background-color 0.5s, color 0.5s;
        background-color: #FFFFFF;
        --primary-bg-color: var(--light-cyan-color);
        --secondary-bg-color: var(--pale-cerulean-color);
        --first-auxiliary-color: var(--metallic-blue-color);
        --second-auxiliary-color: var(--gunmetal-color);
        --primary-font-color: var(--gunmetal-color);
        --secondary-font-color: var(--light-cyan-color);
        --table-primary-color: var(--metallic-blue-color);
        --table-secondary-color: var(--pale-cerulean-50-alpha-color);
        --table-border-color: var(--pale-cerulean-50-alpha-color);
        --contato-font-color: var(--light-cyan-color);
        --chat-scroll-color: var(--metallic-blue-50-alpha-color);
    }
    
    body.dark-theme {
        --primary-bg-color: var(--gunmetal-color);
        --secondary-bg-color: var(--metallic-blue-color);
        --first-auxiliary-color: var(--pale-cerulean-color);
        --second-auxiliary-color: var(--light-cyan-color);
        --primary-font-color: var(--light-cyan-color);
        --secondary-font-color: var(--gunmetal-color);
        --table-primary-color: var(--pale-cerulean-color);
        --table-secondary-color: var(--metallic-blue-50-alpha-color);
        --table-border-color: var(--metallic-blue-50-alpha-color);
        --contato-font-color: var(--gunmetal-color);
        --chat-scroll-color: var(--pale-cerulean-50-alpha-color);
    }
`
