import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { format } from "date-fns"
import { FaFilePdf } from "react-icons/fa6";
import { FiAlertCircle } from "react-icons/fi";

import { Page, Title, DivMain, Table, Theader, Th, Tbody, Trbody, Td, Buttons, Menus, ContainerTable, TdDisciplina, Description, DivText, Card, DivCards, Description2, Legendas } from './styles'
import Button from '../../../Components/Button';
import api from '../../../Services/api';
import { Alert } from '../../../utils/Alert';
import CardVerPdfProgressao from '../../../Components/ProgressaoParcial/CardVerPdfProgressao';
import { Url_Pdf } from '../../../assets/URL\'S/Url_Pdf';

export const ProgressaoParcialAluno = () => {
  const navigate = useNavigate()
  const [progressoes, setProgressoes] = useState([]);
  const [pdfProgressao, setPdfProgressao] = useState(false);
  const [progressaoEdit, setProgressaoEdit] = useState(null);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const isPC = windowSize.width > 1200;

  const listarProgressoes = useCallback(async () => {
    try {
      const res = await api.get("/aluno/listar-progressoes");
      setProgressoes(res.data.flat());
    } catch (err) {
      console.error("Erro ao listar progressões:", err);
    }
  }, []);

  useEffect(() => {
    listarProgressoes();
    const handleResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [listarProgressoes]);

  const downloadPdf = async (pdf, fileName) => {
    try {
      const urlpdf = Url_Pdf(pdf);
      const response = await fetch(urlpdf);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName || 'download.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar PDF:', error);
      Alert('Alert2', "Erro ao baixar o PDF. Por favor, tente novamente.");
    }
  };

  const aceitarProgressao = async (id) => {
    try {
      const result = await Alert("Alert", "Deseja aceitar a progressão?");
      if (result.isConfirmed) {
        const res = await api.put(`aluno/aceitar-progressao?id_progressao=${id}`);
        Alert("Sucesso3", res.data.mensagem || "Progressão aceita com sucesso!");
        listarProgressoes();
      }
    } catch (err) {
      console.error("Erro ao aceitar progressão:", err);
      Alert("Erro", err.response?.data?.erro || "Ocorreu um erro ao aceitar a progressão");
    }
  };

  const renderProgressaoItem = (d, i) => {
    const dataAtual = new Date();
    const dataInicio = new Date(d.data_inicio);
    const dataFim = new Date(d.data_fim);
    const andamento = dataAtual >= dataInicio && dataAtual <= dataFim;
    const terminou = dataAtual > dataFim;
    const turma = d.turma_rh.split(',')[1].split('-').slice(1).join(' - ');
    const curso = d.curso_rh.split(',')[1].replace('TÉCNICO EM', '').trim();
    const disciplina = d.disciplina_rh.split(',')[1];

    const handleProgressaoClick = () => {
      if (terminou) return Alert('Alert2', "Esta progressão já terminou!");
      if (!andamento) return Alert('Alert2', "Esta progressão ainda não começou!");
      if (d.aluno_aceitou === null) return aceitarProgressao(d?.id);
      if (d.pdf && andamento) return downloadPdf(d.pdf, `progressao_${disciplina}.pdf`);
      return Alert('Alert2', "Não há PDF disponível");
    };

    return isPC ? (
      <Trbody key={i}>
        <Td>
          {terminou ? <p><span className='terminou' />{d.professor.split(',')[1]}</p> :
            andamento ? <p><span className='andamento' />{d.professor.split(',')[1]}</p> :
              <p>{d.professor.split(',')[1]}</p>}
        </Td>
        <Td>{curso}</Td>
        <Td>{turma}</Td>
        <TdDisciplina>{disciplina}</TdDisciplina>
        <Td>{format(dataInicio, "dd/MM/yyyy")}</Td>
        <Td>{format(dataFim, "dd/MM/yyyy")}</Td>
        <Td className='icon'>
          {d.aluno_aceitou === null ? (
            <FiAlertCircle
              onClick={handleProgressaoClick}
              size={'1.8rem'}
              cursor={'pointer'}
              title={'Aceite para ver pdf'}
              color={'#535353'}
            />
          ) : (
            <FaFilePdf
              onClick={() => {
                if (!terminou && andamento && d.pdf) {
                  setPdfProgressao(true);
                  setProgressaoEdit(d);
                } else {
                  handleProgressaoClick();
                }
              }}
              size={'1.8rem'}
              cursor={'pointer'}
              title={!d.pdf ? 'Sem pdf' : 'Ver pdf'}
              color={!d.pdf ? '#535353' : '#de000e'}
            />
          )}
        </Td>
        <Td $conceito={d?.conceito} className='conceito'>
          {d?.conceito || '-'}
        </Td>
      </Trbody>
    ) : (
      <Card key={i}
        $terminou={terminou}
        $andamento={andamento}
        $conceito={d?.conceito}
        onClick={handleProgressaoClick}>
        <div>{d?.conceito != null && terminou ? <p className='conceito'>Resultado: {d?.conceito}</p> : null}</div>
        <span />
        <div className='text'><p>Professor:</p> {d.professor.split(',')[1]}</div>
        <div className='text'><p>Curso:</p> {curso} </div>
        <div className='text'><p>Turma:</p>{turma}</div>
        <div className='text'><p>Disciplina:</p>{disciplina}</div>
        <div><p>Data Inicio:</p> {format(dataInicio, "dd/MM/yyyy")}</div>
        <div><p>Data Fim:</p> {format(dataFim, "dd/MM/yyyy")}</div>
        <div>
          {d.aluno_aceitou == null && andamento ? <p className='pdf'>clique para aceitar e ver o PDF</p> :
            d?.pdf && andamento && d?.conceito == null && !terminou ? <p className='pdf'>clique para baixar o pdf</p> : null}
        </div>
        <span />
      </Card>
    );
  };

  return (
    <Page>
      <DivMain>
        <Menus>
          {isPC ? (
            <>
              <Title>Progressão Parcial</Title>
              <Buttons>
                <Button
                  event={() => navigate("/aluno")}
                  width="8rem"
                  margintop="1%"
                  placeholder="Voltar"
                  backgroundcolor="#1a2e35"
                  borderRadius="30px 30px 0px 0px"
                />
              </Buttons>
              {progressoes.length > 0 && (
                <>
                  <Description2>
                    <p>Clique no icone: <FiAlertCircle title={'Aceite para ver pdf'} size={'1.1rem'} color={'#ffffff'} /></p>
                    <p>Para aceitar e ver o PDF</p>
                  </Description2>
                  <Description>
                    <p><span className='andamento' />Em Andamento</p>
                    <p><span className='terminou' />Encerradas</p>
                  </Description>
                </>
              )}
            </>
          ) : (
            <>
              <Buttons>
                <Button
                  event={() => navigate("/aluno")}
                  width="8rem"
                  margintop="0%"
                  placeholder="Voltar"
                  backgroundcolor="#1a2e35"
                  borderRadius="30px 30px 0px 0px"
                />
              </Buttons>
              <Legendas>
                <Title>Progressão Parcial</Title>
                {progressoes.length > 0 && (
                  <Description>
                    <p><span />Não começou</p>
                    <p><span className='andamento' />Em Andamento</p>
                    <p><span className='terminou' />Encerradas</p>
                  </Description>
                )}
              </Legendas>
            </>
          )}
        </Menus>
        <ContainerTable>
          {isPC ? (
            progressoes.length > 0 ? (
              <Table>
                <Theader>
                  <tr>
                    <Th>Professor</Th>
                    <Th>Curso</Th>
                    <Th>Turma</Th>
                    <Th>Disciplina</Th>
                    <Th>Início</Th>
                    <Th>Término</Th>
                    <Th>PDF <p>Progressão</p></Th>
                    <Th>Resultado</Th>
                  </tr>
                </Theader>
                <Tbody>
                  {progressoes.map(renderProgressaoItem)}
                </Tbody>
              </Table>
            ) : (
              <>
                <Table>
                  <Theader>
                    <tr>
                      <Th>Professor</Th>
                      <Th>Curso</Th>
                      <Th>Turma</Th>
                      <Th>Disciplina</Th>
                      <Th>Começo</Th>
                      <Th>Encerramento</Th>
                      <Th>PDF <p>Progressão</p></Th>
                    </tr>
                  </Theader>
                </Table>
                <DivText>Você não está em Progressão</DivText>
              </>
            )
          ) : (
            progressoes.length > 0 ? (
              <DivCards>
                {progressoes.map(renderProgressaoItem)}
              </DivCards>
            ) : (
              <DivText>Você não está em Progressão</DivText>
            )
          )}
        </ContainerTable>
      </DivMain>
      <CardVerPdfProgressao Show={pdfProgressao} setShow={setPdfProgressao} progressaoEdit={progressaoEdit} />
    </Page>
  );
};