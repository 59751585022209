import React, { useEffect, useState } from "react"
import { Buttons, Card, Contanier, Foids, Header, Title } from "./style"
import Input from "../../Input"
import Button from "../../Button"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import QRCode from "react-qr-code"
import axios from "axios"
import { Link } from "react-router-dom"

export default function QrCodeDadosPublicos({ isShow, setIsShow }) {
  const [aluno, setAluno] = useState("")

  useEffect(() => {
    const nome = localStorage.getItem("token2")
    setAluno(nome)
  }, [])

  const linkAlunoDev = "/v/" + aluno
  const linkAlunoProdTeste = "https://testecart.fiecdev.com.br/v/" + aluno
  const linkAlunoProd = "https://alunos.fiecdev.com.br/v/" + aluno
  const linkQrcode = window.location.href.includes("localhost") || window.location.href.includes("10") ? linkAlunoDev :
    window.location.href.includes("testecart") ? linkAlunoProdTeste : linkAlunoProd

  if (isShow == true) {
    if (!aluno) {
      Alert("Alert3", "Problemas ao gerar QR CODE")
      setIsShow(false)
    }
    return (
      <Contanier>
        <Buttons onClick={() => { setIsShow(false) }}>
          <Card>
            <QRCode value={linkQrcode} size={"100%"} level="Q" />
            {
              window.location.href.includes("localhost") || window.location.href.includes("10") ?
                <a href={linkQrcode} target="_blank" rel="noopener noreferrer"> <Button id={"button"} width="20vh" height="6vh" placeholder="Abrir Link" /> </a>
                : null
            }
          </Card>
        </Buttons>

      </Contanier>
    )
  } else if (isShow == false) {
    return null
  }
}
