import styled from "styled-components"

export const Container = styled.div`
  width: 90%;
  margin-left: 5%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // border:5px solid black;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const ContainerTable = styled.div`
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 5px solid #1a2e35;
  border-bottom: 5px solid #1a2e35;
  // border:5px solid blue;
  // margin-top:20px;
  // Hide scrollbar for IE, Edge and Firefox 
  /*-ms-overflow-style: none;   IE and Edge 
  scrollbar-width: none;  // Firefox 
  // Hide scrollbar for Chrome, Safari, and Opera 
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  } */
`
export const Title = styled.h1`
  padding: 20px 20px;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  font-weight: 200;
  font-size: #1a2e35;
  border-radius: 20px 20px 0px 0px;
`
export const Menus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`
export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
  overflow: scroll;
`
export const Theader = styled.thead`
  width: 89.2vw;
  position: sticky;
  top: 0;
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  z-index: 9;
  /* opacity: 0; */
`
export const Tbody = styled.tbody` 
  height: fit-content;
`;

export const Th = styled.th`
  padding: 0px 15px;
  text-align: start;
`
export const Td = styled.td`
  padding: 2px 25px;
  text-align: start;
`
export const TdNome = styled.td`
  padding: 2px 15px;
  text-align: start;
`

export const Trbody = styled.tr`
  border-bottom: 1px solid #dddddd;
  height: 50px;
  overflow: scroll;
  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  transition: all 0.2s;

  &:hover {
    background-color: #9c9c9c;
    color: white;
    transform: scale(1.02);
  }
`
export const Colletion = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 40%;
  height: 90%;
`
export const Buttons = styled.div`
  margin-left: 2vh;
  width: 70%;
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  gap: 20px;
  // justify-content: space-between;
  // height: 23%;
`
export const Grup = styled.div`
  align-items: center;
  justify-content: start;
  gap: 8px;
  display: flex;
  flex-direction: column;
`
export const Boxdiv = styled.div`
  width: 20%;
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: 2rem;
  height: 75%;
  background-color: #1a2e35;
  border-radius: 30px 30px 0px 0px;
  justify-content: center;
  align-items: center;
`

export const Mostrando = styled.h4`
  padding: 5%;
`

