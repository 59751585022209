import styled from "styled-components";

export const Container = styled.div`
  text-align: start;
  justify-content: start;
`;

export const Labell = styled.label`
  color: #f0f0f0;
`

export const Select = styled.select`
  scroll-behavior: smooth;
  width: 20vh;
  margin-top: 5px;
  // box-shadow: 1px 1.5px 6px black;
  height: 30px;
  border-radius: 20px;
  border-top: none;
  border-bottom: 2px solid white;
  display: flex;
  text-align-last: center;
  background-color: transparent;
  transition: all 0.2s;
  text-align: center;
  color: #f0f0f0;
  -webkit-appearance: none;
  font-size: 90%;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: gray;
    background-clip: content-box; /* THIS IS IMPORTANT */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray;
  }
  &:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  &:focus{
    border-image: linear-gradient(to right, #3a3f86, #5056ac);
  }
`;
