import React from "react";
import "./style.css";

export default function Input(props) {
  // Definindo propriedades adicionais para o campo de entrada com base no tipo
  const inputProps = {};
  if (props.type === "date") {
    if (props.min) inputProps.min = props.min;
    if (props.max) inputProps.max = props.max;
  }

  return (
    <div className="form__group field"
      style={{
        width: props.widthDiv,
        marginLeft: props.marginleftDiv,
        marginRight: props.marginrightDiv,
      }}>
      <input
        type={props.type}
        className="form__field"
        placeholder={props.placeholder}
        required=""
        value={props.value}
        disabled={props.disabled}
        onChange={props.event}
        style={{
          color: props.color,
          width: props.width,
          height: props.height,
          marginLeft: props.marginleft,
          marginRight: props.marginright,
          marginBottom: props.marginbottom,
          marginTop: props.margintop,
          border: props.border,
          borderRadius: props.borderradius,
          alignItems: props.alignitems,
        }}
        {...inputProps} // Espalhando as propriedades adicionais
      />
      <label
        htmlFor={props.placeholder}
        style={{ color: props.placeholdercolor }}
        className="form__label"
      >
        {props.placeholder}
      </label>
    </div>
  );
}
