import React from "react"
import { Container, Select, Labell } from "./style"

const InputSelect = ({ Label, Style1, Default, Disabled, Options, OnChange, Required, Style2 }) => {
  return (
    <Container>
      <Labell style={Style1} for={Label}>
        {Label}
      </Labell>
      <Select defaultValue={Default} onChange={OnChange} required={Required} style={Style2}>
        {!Disabled || (
          <option disabled selected hidden>
            {Disabled}
          </option>
        )}
        {Options?.map((option) => {
          return (
            <option
              style={{ color: "#f0f0f0", backgroundColor: "var(--azul)" }}
              value={option.value}
              key={option.value}
              label={option.label}
            />
          )
        })}
      </Select>
    </Container>
  )
}

export default InputSelect
