import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`
export const FirstDiv = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    background-color: var(--azul);
    padding: 5rem;
    align-items: center;
`

export const Form = styled.form`
    display: flex;
    border-radius: 20px solid var(--azul);
`

export const SearchDiv = styled.div`
    place-items: center;
`